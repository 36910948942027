import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/ShoeRepair';

const listShoeRepairReducer = handleActions(
    {
        [Actions.ListShoeRepairRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListShoeRepairSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListShoeRepairFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListShoeRepairClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailShoeRepairReducer = handleActions(
    {
        [Actions.DetailShoeRepairRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailShoeRepairSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailShoeRepairFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailShoeRepairClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const serviceOptionsReducer = handleActions(
    {
        [Actions.GetServiceOptionsRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.GetServiceOptionsSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.GetServiceOptionsFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.GetServiceOptionsClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const servicePricesReducer = handleActions(
    {
        [Actions.GetServicePricesRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.GetServicePricesSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.GetServicePricesFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: '',
            errors: action.payload.errors,
        }),
        [Actions.GetServicePricesClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: '',
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: '',
        errors: {},
    }
);

const processShoeRepairReducer = handleActions(
    {
        [Actions.ProcessShoeRepairRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ProcessShoeRepairSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ProcessShoeRepairFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ProcessShoeRepairClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const cancelShoeRepairReducer = handleActions(
    {
        [Actions.CancelShoeRepairRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CancelShoeRepairSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CancelShoeRepairFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CancelShoeRepairClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const completeShoeRepairReducer = handleActions(
    {
        [Actions.CompleteShoeRepairRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CompleteShoeRepairSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CompleteShoeRepairFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CompleteShoeRepairClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listShoeRepairReducer,
    detail: detailShoeRepairReducer,
    service_options: serviceOptionsReducer,
    service_prices: servicePricesReducer,
    process: processShoeRepairReducer,
    cancel: cancelShoeRepairReducer,
    complete: completeShoeRepairReducer,
});
