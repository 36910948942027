import {createAction} from "redux-actions";

export const ListBoxManageRequest = createAction('LIST_BOX_MANAGE_REQUEST');
export const ListBoxManageSuccess = createAction('LIST_BOX_MANAGE_SUCCESS');
export const ListBoxManageFailed = createAction('LIST_BOX_MANAGE_FAILED');
export const ListBoxManageClear = createAction('LIST_BOX_MANAGE_CLEAR');

export const ExportCsvBoxManageRequest    = createAction('EXPORT_CSV_BOX_MANAGE_REQUEST');
export const ExportCsvBoxManageSuccess    = createAction('EXPORT_CSV_BOX_MANAGE_SUCCESS');
export const ExportCsvBoxManageFailed     = createAction('EXPORT_CSV_BOX_MANAGE_FAILED');
export const ExportCsvBoxManageClear      = createAction('EXPORT_CSV_BOX_MANAGE_CLEAR');

export const GetIdCollectRequest = createAction('GET_ID_COLLECT_REQUEST');
export const GetIdCollectSuccess = createAction('GET_ID_COLLECT_SUCCESS');
export const GetIdCollectFailed = createAction('GET_ID_COLLECT_FAILED');
export const GetIdCollectClear = createAction('GET_ID_COLLECT_CLEAR');

export const GetIdBoxRequest = createAction('GET_ID_BOX_REQUEST');
export const GetIdBoxSuccess = createAction('GET_ID_BOX_SUCCESS');
export const GetIdBoxFailed = createAction('GET_ID_BOX_FAILED');
export const GetIdBoxClear = createAction('GET_ID_BOX_CLEAR');

export const InputBoxRequest = createAction('INPUT_BOX_REQUEST');
export const InputBoxSuccess = createAction('INPUT_BOX_SUCCESS');
export const InputBoxFailed = createAction('INPUT_BOX_FAILED');
export const InputBoxClear = createAction('INPUT_BOX_CLEAR');

export const DetailBoxManageRequest = createAction('DETAIL_BOX_MANAGE_REQUEST');
export const DetailBoxManageSuccess = createAction('DETAIL_BOX_MANAGE_SUCCESS');
export const DetailBoxManageFailed = createAction('DETAIL_BOX_MANAGE_FAILED');
export const DetailBoxManageClear = createAction('DETAIL_BOX_MANAGE_CLEAR');

export const RegisterClosetRequest = createAction('REGISTER_CLOSET_REQUEST');
export const RegisterClosetSuccess = createAction('REGISTER_CLOSET_SUCCESS');
export const RegisterClosetFailed = createAction('REGISTER_CLOSET_FAILED');
export const RegisterClosetClear = createAction('REGISTER_CLOSET_CLEAR');

export const RegisterBoxRequest = createAction('REGISTER_BOX_REQUEST');
export const RegisterBoxSuccess = createAction('REGISTER_BOX_SUCCESS');
export const RegisterBoxFailed = createAction('REGISTER_BOX_FAILED');
export const RegisterBoxClear = createAction('REGISTER_BOX_CLEAR');

export const RegisterItemRequest = createAction('REGISTER_ITEM_REQUEST');
export const RegisterItemSuccess = createAction('REGISTER_ITEM_SUCCESS');
export const RegisterItemFailed = createAction('REGISTER_ITEM_FAILED');
export const RegisterItemClear = createAction('REGISTER_ITEM_CLEAR');

export const GetBoxSizeRequest = createAction('GET_BOX_SIZE_REQUEST');
export const GetBoxSizeSuccess = createAction('GET_BOX_SIZE_SUCCESS');
export const GetBoxSizeFailed = createAction('GET_BOX_SIZE_FAILED');
export const GetBoxSizeClear = createAction('GET_BOX_SIZE_CLEAR');

export const GetBoxExtendRequest = createAction('GET_BOX_EXTEND_REQUEST');
export const GetBoxExtendSuccess = createAction('GET_BOX_EXTEND_SUCCESS');
export const GetBoxExtendFailed = createAction('GET_BOX_EXTEND_FAILED');
export const GetBoxExtendClear = createAction('GET_BOX_EXTEND_CLEAR');

export const GetPlanBoxRequest = createAction('GET_PLAN_BOX_REQUEST');
export const GetPlanBoxSuccess = createAction('GET_PLAN_BOX_SUCCESS');
export const GetPlanBoxFailed = createAction('GET_PLAN_BOX_FAILED');
export const GetPlanBoxClear = createAction('GET_PLAN_BOX_CLEAR');
