import { createAction } from 'redux-actions';

export const ListMembershipRequest = createAction('LIST_MEMBERSHIP_REQUEST');
export const ListMembershipSuccess = createAction('LIST_MEMBERSHIP_SUCCESS');
export const ListMembershipFailed = createAction('LIST_MEMBERSHIP_FAILED');
export const ListMembershipClear = createAction('LIST_MEMBERSHIP_CLEAR');

export const DetailMembershipRequest = createAction('DETAIL_MEMBERSHIP_REQUEST');
export const DetailMembershipSuccess = createAction('DETAIL_MEMBERSHIP_SUCCESS');
export const DetailMembershipFailed = createAction('DETAIL_MEMBERSHIP_FAILED');
export const DetailMembershipClear = createAction('DETAIL_MEMBERSHIP_CLEAR');

export const CreateMembershipRequest = createAction('CREATE_MEMBERSHIP_REQUEST');
export const CreateMembershipSuccess = createAction('CREATE_MEMBERSHIP_SUCCESS');
export const CreateMembershipFailed = createAction('CREATE_MEMBERSHIP_FAILED');
export const CreateMembershipClear = createAction('CREATE_MEMBERSHIP_CLEAR');

export const UpdateMembershipRequest = createAction('UPDATE_MEMBERSHIP_REQUEST');
export const UpdateMembershipSuccess = createAction('UPDATE_MEMBERSHIP_SUCCESS');
export const UpdateMembershipFailed = createAction('UPDATE_MEMBERSHIP_FAILED');
export const UpdateMembershipClear = createAction('UPDATE_MEMBERSHIP_CLEAR');

export const DeleteMembershipRequest = createAction('DELETE_MEMBERSHIP_REQUEST');
export const DeleteMembershipSuccess = createAction('DELETE_MEMBERSHIP_SUCCESS');
export const DeleteMembershipFailed = createAction('DELETE_MEMBERSHIP_FAILED');
export const DeleteMembershipClear = createAction('DELETE_MEMBERSHIP_CLEAR');

export const ResetPasswordMembershipRequest = createAction('RESET_PASSWORD_MEMBERSHIP_REQUEST');
export const ResetPasswordMembershipSuccess = createAction('RESET_PASSWORD_MEMBERSHIP_SUCCESS');
export const ResetPasswordMembershipFailed = createAction('RESET_PASSWORD_MEMBERSHIP_FAILED');
export const ResetPasswordMembershipClear = createAction('RESET_PASSWORD_MEMBERSHIP_CLEAR');