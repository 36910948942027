import { createSelector } from 'reselect';
import _ from 'lodash';

export const createListRequestReturnSelector = () => {
    return createSelector([(state) => state.request.list.data], (items) => {
        if (_.isEmpty(items)) return {};
        return {
            count: items.count,
            total_pages: items.total_pages,
            page: items.page,
            rowsPerPage: items.rowsPerPage,
            content: items.content,
        };
    });
};

export const createReturnPriceSelector = () => {
    return createSelector([(state) => state.request.price.data], (price) => {
        if (_.isEmpty(price.boxes)) return {};
        let priceOptions = [{ id: '', name: '' }];
        let priceFilter = {};
        let priceFilterByName = {};
        price.boxes.forEach((p) => {
            priceOptions.push({ id: p.plan_box_id, name: p.name });
            priceFilter = { ...priceFilter, [p.plan_box_id]: p.price };
            priceFilterByName = {
                ...priceFilterByName,
                [p.name]: p.plan_box_id,
            };
        });
        return {
            options: priceOptions,
            filter_price: priceFilter,
            filter_by_name: priceFilterByName,
        };
    });
};
