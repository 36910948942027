import { createAction } from 'redux-actions';

export const ListOrderRequest         = createAction('LIST_ORDER_REQUEST');
export const ListOrderSuccess         = createAction('LIST_ORDER_SUCCESS');
export const ListOrderFailed          = createAction('LIST_ORDER_FAILED');
export const ListOrderClear           = createAction('LIST_ORDER_CLEAR');

export const DetailOrderRequest       = createAction('DETAIL_ORDER_REQUEST');
export const DetailOrderSuccess       = createAction('DETAIL_ORDER_SUCCESS');
export const DetailOrderFailed        = createAction('DETAIL_ORDER_FAILED');
export const DetailOrderClear         = createAction('DETAIL_ORDER_CLEAR');

export const ExportCsvOrderRequest    = createAction('EXPORT_CSV_ORDER_REQUEST');
export const ExportCsvOrderSuccess    = createAction('EXPORT_CSV_ORDER_SUCCESS');
export const ExportCsvOrderFailed     = createAction('EXPORT_CSV_ORDER_FAILED');
export const ExportCsvOrderClear      = createAction('EXPORT_CSV_ORDER_CLEAR');

export const CancelOrderRequest    = createAction('CANCEL_ORDER_REQUEST');
export const CancelOrderSuccess    = createAction('CANCEL_ORDER_SUCCESS');
export const CancelOrderFailed     = createAction('CANCEL_ORDER_FAILED');
export const CancelOrderClear      = createAction('CANCEL_ORDER_CLEAR');

export const DeliveredOrderRequest    = createAction('DELIVERED_ORDER_REQUEST');
export const DeliveredOrderSuccess    = createAction('DELIVERED_ORDER_SUCCESS');
export const DeliveredOrderFailed     = createAction('DELIVERED_ORDER_FAILED');
export const DeliveredOrderClear      = createAction('DELIVERED_ORDER_CLEAR');