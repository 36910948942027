import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    card: {
        border: '1px solid #90caf975',
        boxShadow: 'none',
        marginBottom: '10px',
    },
    title: {
        color: '#2196f3',
        fontWeight: 'bold',
    },
    darkTitle: {
        color: 'black',
        fontWeight: 'bold',
    },
    rootCardContent: {
        width: 200,
    },
    action: {
        margin: 0,
    },
}));

// ===========================|| CUSTOM MAIN CARD ||=========================== //

// eslint-disable-next-line react/display-name
const MainCard = React.forwardRef(
    ({ children, content = true, secondary, title, cardSetting }, ref) => {
        const classes = useStyles();

        return (
            <Card ref={ref} className={classes.card}>
                {/* card header and action */}
                {title && (
                    <CardHeader
                        title={title}
                        action={secondary}
                        classes={{
                            title: classes.title,
                            action: classes.action,
                        }}
                    />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent
                        classes={{
                            root: cardSetting && classes.rootCardContent,
                        }}
                    >
                        {children}
                    </CardContent>
                )}
            </Card>
        );
    }
);

MainCard.defaultProps = {
    cardSetting: false,
};

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    cardSetting: PropTypes.bool,
};

export default MainCard;
