import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Collect';
import { waitAuthorizedCheck } from './Auth';
import format from 'date-fns/format';

function* getListCollect(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/collection/search?size=30&page=${
        action.payload?.get?.page ?? 0
    }&sort=${action.payload?.get?.sort ?? 'orderDate,desc'}`;
    let bodyRequest = { status: 'NEW' };
    if (action.payload?.get)
        bodyRequest = { ...bodyRequest, ...action.payload.get };

    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListCollectSuccess(response));
    } catch (error) {
        yield put(Actions.ListCollectFailed(error));
    }
}

function* getDetailCollect(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/collection/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailCollectSuccess(response));
    } catch (error) {
        yield put(Actions.DetailCollectFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/order/collection/process?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            body: action.payload.orderIds ?? [],
            filename: `集荷依頼_${format(new Date(), 'yyyyMMdd')}.csv`,
        });
        yield put(Actions.ExportCsvCollectSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvCollectFailed(error));
    }
}

function* cancelCollect(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/collection/${action.payload}/cancel`,
            token: credential.data.access_token,
        });
        yield put(Actions.CancelCollectSuccess(response));
    } catch (error) {
        yield put(Actions.CancelCollectFailed(error));
    }
}

function* deliveredCollect(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/collection/${action.payload.order_id}/complete`,
            body: {
                code: action.payload.code,
            },
            token: credential.data.access_token,
        });
        yield put(Actions.DeliveredCollectSuccess(response));
    } catch (error) {
        yield put(Actions.DeliveredCollectFailed(error));
    }
}

export default function* Collect() {
    yield all([
        takeLatest('LIST_COLLECT_REQUEST', getListCollect),
        takeLatest('DETAIL_COLLECT_REQUEST', getDetailCollect),
        takeLatest('EXPORT_CSV_COLLECT_REQUEST', exportCsv),
        takeLatest('CANCEL_COLLECT_REQUEST', cancelCollect),
        takeLatest('DELIVERED_COLLECT_REQUEST', deliveredCollect),
    ]);
}
