import { createAction } from "redux-actions";

export const ListCleaningRequest           = createAction('LIST_CLEANING_REQUEST');
export const ListCleaningSuccess           = createAction('LIST_CLEANING_SUCCESS');
export const ListCleaningFailed            = createAction('LIST_CLEANING_FAILED');
export const ListCleaningClear             = createAction('LIST_CLEANING_CLEAR');

export const DetailCleaningRequest         = createAction('DETAIL_CLEANING_REQUEST');
export const DetailCleaningSuccess         = createAction('DETAIL_CLEANING_SUCCESS');
export const DetailCleaningFailed          = createAction('DETAIL_CLEANING_FAILED');
export const DetailCleaningClear           = createAction('DETAIL_CLEANING_CLEAR');

export const RegisterCleaningRequest        = createAction('REGISTER_CLEANING_REQUEST');
export const RegisterCleaningSuccess        = createAction('REGISTER_CLEANING_SUCCESS');
export const RegisterCleaningFailed         = createAction('REGISTER_CLEANING_FAILED');
export const RegisterCleaningClear          = createAction('REGISTER_CLEANING_CLEAR');

export const CancelCleaningRequest         = createAction('CANCEL_CLEANING_REQUEST');
export const CancelCleaningSuccess         = createAction('CANCEL_CLEANING_SUCCESS');
export const CancelCleaningFailed          = createAction('CANCEL_CLEANING_FAILED');
export const CancelCleaningClear           = createAction('CANCEL_CLEANING_CLEAR');

export const CompleteCleaningRequest       = createAction('COMPLETE_CLEANING_REQUEST');
export const CompleteCleaningSuccess       = createAction('COMPLETE_CLEANING_SUCCESS');
export const CompleteCleaningFailed        = createAction('COMPLETE_CLEANING_FAILED');
export const CompleteCleaningClear         = createAction('COMPLETE_CLEANING_CLEAR');
