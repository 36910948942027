import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import customizationReducer from './customizationReducer';
import AlertReducer from './Alert';
import GlobalReducer from './Global';
import AuthReducer from './Auth';
import BoxReducer from './Box';
import AccountReducer from './Account';
import MembershipReducer from './Membership';
import Collect from './Collect';
import OrderReducer from './Order';
import ItemReducer from './Item';
import BoxManageReducer from './BoxManage';
import RequestReducer from './Request';
import NotificationReducer from './Notification';
import InvoiceReducer from './Invoice';
import CleaningReducer from './Cleaning';
import DataMasterReducer from './DataMaster';
import HangerReducer from './Hanger';
import ShoeRepairReducer from './ShoeRepair';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        customization: customizationReducer,
        alert: AlertReducer,
        global: GlobalReducer,
        auth: AuthReducer,
        box: BoxReducer,
        account: AccountReducer,
        membership: MembershipReducer,
        collect: Collect,
        order: OrderReducer,
        item: ItemReducer,
        boxManage: BoxManageReducer,
        request: RequestReducer,
        notification: NotificationReducer,
        invoice: InvoiceReducer,
        dataMaster: DataMasterReducer,
        hanger: HangerReducer,
        shoe_repair: ShoeRepairReducer,
        cleaning: CleaningReducer,
    });

export default createRootReducer;
