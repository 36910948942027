export const APIErrors = {
    badRequest: 'BAD_REQUEST',
    authExpired: 'AUTH_EXPIRED',
    permissionDenied: 'PERMISSION_DENIED',
    server: 'SERVER_ERROR',
    maintenance: 'MAINTENANCE_ERROR',
    network: 'NETWORK_ERROR',
};

Object.freeze(APIErrors);
