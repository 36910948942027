/**
 * Color intention that you want to used in your theme
 */
export default function themePalette() {
    return {
        common: {
            black: '#000000',
            white: '#ffffff',
        },
        primary: {
            light: '#e3f2fd',
            main: '#2196f3',
            dark: '#1e88e5',
            200: '#90caf9',
            800: '#1565c0',
        },
        secondary: {
            light: '#ede7f6',
            main: '#673ab7',
            dark: '#5e35b1',
            200: '#b39ddb',
            800: '#4527a0',
        },
        error: {
            light: '#ef9a9a',
            main: '#f44336',
            dark: '#c62828',
        },
        orange: {
            light: '#fbe9e7',
            main: '#ffab91',
            dark: '#d84315',
        },
        warning: {
            light: '#fff8e1',
            main: '#ffe57f',
            dark: '#ffc107',
        },
        success: {
            light: '#b9f6ca',
            200: '#69f0ae',
            // main: '#00e676',
            main: '#00c853',
            dark: '#00c853',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#0000000d',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            900: '#212121',
        },
        background: {
            paper: '#ffffff',
            default: '#ffffff',
        },
    };
}
