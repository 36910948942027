import {createAction} from "redux-actions";

export const ListNotificationRequest        = createAction('LIST_NOTIFICATION_REQUEST');
export const ListNotificationSuccess        = createAction('LIST_NOTIFICATION_SUCCESS');
export const ListNotificationFailed         = createAction('LIST_NOTIFICATION_FAILED');
export const ListNotificationClear          = createAction('LIST_NOTIFICATION_CLEAR');

export const DetailNotificationRequest      = createAction('DETAIL_NOTIFICATION_REQUEST');
export const DetailNotificationSuccess      = createAction('DETAIL_NOTIFICATION_SUCCESS');
export const DetailNotificationFailed       = createAction('DETAIL_NOTIFICATION_FAILED');
export const DetailNotificationClear        = createAction('DETAIL_NOTIFICATION_CLEAR');

export const CreateNotificationRequest      = createAction('CREATE_NOTIFICATION_REQUEST');
export const CreateNotificationSuccess      = createAction('CREATE_NOTIFICATION_SUCCESS');
export const CreateNotificationFailed       = createAction('CREATE_NOTIFICATION_FAILED');
export const CreateNotificationClear        = createAction('CREATE_NOTIFICATION_CLEAR');
export const CreateNotificationMsgClear     = createAction('CREATE_NOTIFICATION_MSG_CLEAR');

export const UpdateNotificationRequest      = createAction('UPDATE_NOTIFICATION_REQUEST');
export const UpdateNotificationSuccess      = createAction('UPDATE_NOTIFICATION_SUCCESS');
export const UpdateNotificationFailed       = createAction('UPDATE_NOTIFICATION_FAILED');
export const UpdateNotificationClear        = createAction('UPDATE_NOTIFICATION_CLEAR');

export const EndNotificationRequest         = createAction('END_NOTIFICATION_REQUEST');
export const EndNotificationSuccess         = createAction('END_NOTIFICATION_SUCCESS');
export const EndNotificationFailed          = createAction('END_NOTIFICATION_FAILED');
export const EndNotificationClear           = createAction('END_NOTIFICATION_CLEAR');
