import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Item';
import { waitAuthorizedCheck } from './Auth';
import { generatePresignedUrl } from './GeneratePresignedUrl';
import format from 'date-fns/format';

function* getListItem(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/box/item/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'receivedDate,desc'}`;

    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...action.payload?.body },
            token: credential.data.access_token,
        });
        yield put(Actions.ListItemSuccess(response));
    } catch (error) {
        yield put(Actions.ListItemFailed(error));
    }
}

function* getDetailItem(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/box/item/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailItemSuccess(response));
    } catch (error) {
        yield put(Actions.DetailItemFailed(error));
    }
}

function* updateItem(action) {
    const credential = yield call(waitAuthorizedCheck);
    let imageUrl = '';
    if (action.payload.file) {
        imageUrl = yield call(generatePresignedUrl, action.payload.file);
    }

    try {
        const response = yield call(API.put, {
            endpoint: `/api/admin/box/item/${action.payload.boxItemId}`,
            body: {
                ...(action.payload.category_id && {
                    category_id: action.payload.category_id,
                }),
                ...(action.payload.file && {
                    image_url: imageUrl,
                }),
            },
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateItemSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateItemFailed(error));
    }
}

function* deleteItem(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.delete, {
            endpoint: `/api/admin/box/item/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DeleteItemSuccess(response));
    } catch (error) {
        yield put(Actions.DeleteItemFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/box/item/export/all?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            filename: `アイテム管理_${format(new Date(), 'yyyyMMdd')}.csv`,
        });
        yield put(Actions.ExportCsvItemSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvItemFailed(error));
    }
}

function* countItemBox() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: '/api/admin/box/item/count/processing',
            token: credential.data.access_token,
        });
        yield put(Actions.CountItemBoxSuccess(response));
    } catch (error) {
        yield put(Actions.CountItemBoxClear(error));
    }
}

export default function* Order() {
    yield all([
        takeLatest('LIST_ITEM_REQUEST', getListItem),
        takeLatest('DETAIL_ITEM_REQUEST', getDetailItem),
        takeLatest('UPDATE_ITEM_REQUEST', updateItem),
        takeLatest('DELETE_ITEM_REQUEST', deleteItem),
        takeLatest('EXPORT_CSV_ITEM_REQUEST', exportCsv),
        takeLatest('COUNT_ITEM_BOX_REQUEST', countItemBox),
    ]);
}
