import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../Dialog/AlertDialog';
import { CloseDialogNetworkError } from '../../actions/Alert';

const DialogNetworkError = () => {
    const dispatch = useDispatch();
    const dialogNetworkErrorSelector = useSelector(
        (state) => state.alert?.dialogNetworkError
    );

    return (
        <>
            <AlertDialog
                isOpen={dialogNetworkErrorSelector.isOpen}
                onSubmit={() => {
                    dispatch(CloseDialogNetworkError());
                }}
                contentDialog={dialogNetworkErrorSelector.content}
                twoButton={false}
            />
        </>
    );
};

export default DialogNetworkError;
