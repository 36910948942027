import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Box';

const listBoxReducer = handleActions(
    {
        [Actions.ListBoxRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListBoxSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: action.payload,
            errors: {},
        }),
        [Actions.ListBoxFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListBoxClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listBoxReducer,
});
