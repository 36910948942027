import {
    call,
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isAfter } from 'date-fns';
import API from '../service/API';
import * as Actions from '../actions/Auth';
import * as ActionsAlert from '../actions/Alert';
import _ from 'lodash';
import { APIErrors } from '../constants/APIErrors';

function* login(action) {
    try {
        const response = yield call(API.login, {
            endpoint: '/api/auth/admin/signin',
            body: {
                username: action.payload.username.trim(),
                password: action.payload.password.trim(),
                remember: false,
            },
        });
        yield put(Actions.LoginSuccess(response));
        yield put(push('/admin/order'));
    } catch (error) {
        yield put(Actions.LoginFailed(error));
    }
}

function* refreshToken() {
    try {
        const refresh_token = yield select(
            (state) => state.auth.credential.data.refresh_token
        );

        const response = yield call(API.login, {
            endpoint: '/api/auth/refresh',
            body: { token: refresh_token },
        });
        yield put(Actions.RefreshTokenSuccess(response));
    } catch (error) {
        yield put(Actions.LoginClear());
        yield* logout();
    }
}

function* handleAuthFailures(action) {
    if (action.type.match(/^.+_FAILED$/)) {
        const errorCode = action.payload.errors && action.payload.errors.code;
        if (action.type !== 'LOGIN_FAILED') {
            switch (errorCode) {
                case APIErrors.authExpired:
                    break;
                case APIErrors.permissionDenied:
                    yield put(Actions.LoginClear());
                    yield* logout();
                    break;
                case APIErrors.server:
                    yield put(push('/500'));
                    break;
            }
        }
    }
}

export function* waitAuthorizedCheck() {
    const isAppOnline = window.navigator.onLine;
    if (!isAppOnline) {
        yield put(ActionsAlert.OpenDialogNetworkError());
        return;
    }

    const credential = yield select((state) => state.auth.credential);
    const expiredTime = yield select(
        (state) => state.auth.credential.expired_time
    );
    const isTokenExpiredTime = isAfter(new Date(), new Date(expiredTime));

    if (_.isEmpty(credential.data)) {
        yield put(Actions.LoginClear());
        yield* logout();
    }

    if (isTokenExpiredTime) {
        yield put(Actions.RefreshTokenRequest());
        yield take(['REFRESH_TOKEN_SUCCESS']);
        return yield select((state) => state.auth.credential);
    }

    if (!credential.isAuthorized) {
        yield take('LOGIN_SUCCESS');
        return yield select((state) => state.auth.credential);
    }
    return credential;
}

function* logout() {
    yield put(push('/admin'));
}

export default function* Auth() {
    yield all([
        takeEvery('LOGIN_REQUEST', login),
        takeLatest('REFRESH_TOKEN_REQUEST', refreshToken),
        takeEvery('*', handleAuthFailures),
        takeLatest('LOGOUT_REQUESTED', logout),
    ]);
}
