import { createAction } from 'redux-actions';

export const ListInvoiceRequest         = createAction('LIST_INVOICE_REQUEST');
export const ListInvoiceSuccess         = createAction('LIST_INVOICE_SUCCESS');
export const ListInvoiceFailed          = createAction('LIST_INVOICE_FAILED');
export const ListInvoiceClear           = createAction('LIST_INVOICE_CLEAR');

export const DetailInvoiceRequest       = createAction('DETAIL_INVOICE_REQUEST');
export const DetailInvoiceSuccess       = createAction('DETAIL_INVOICE_SUCCESS');
export const DetailInvoiceFailed        = createAction('DETAIL_INVOICE_FAILED');
export const DetailInvoiceClear         = createAction('DETAIL_INVOICE_CLEAR');

export const CreateInvoiceRequest       = createAction('CREATE_INVOICE_REQUEST');
export const CreateInvoiceSuccess       = createAction('CREATE_INVOICE_SUCCESS');
export const CreateInvoiceFailed        = createAction('CREATE_INVOICE_FAILED');
export const CreateInvoiceClear         = createAction('CREATE_INVOICE_CLEAR');

export const InvoicePeriodRequest       = createAction('INVOICE_PERIOD_REQUEST');
export const InvoicePeriodSuccess       = createAction('INVOICE_PERIOD_SUCCESS');
export const InvoicePeriodFailed        = createAction('INVOICE_PERIOD_FAILED');
export const InvoicePeriodClear         = createAction('INVOICE_PERIOD_CLEAR');

export const PayInvoiceRequest          = createAction('PAY_INVOICE_REQUEST');
export const PayInvoiceSuccess          = createAction('PAY_INVOICE_SUCCESS');
export const PayInvoiceFailed           = createAction('PAY_INVOICE_FAILED');
export const PayInvoiceClear            = createAction('PAY_INVOICE_CLEAR');

export const UnconfirmedCountRequest    = createAction('UNCONFIRMED_COUNT_REQUEST');
export const UnconfirmedCountSuccess    = createAction('UNCONFIRMED_COUNT_SUCCESS');
export const UnconfirmedCountFailed     = createAction('UNCONFIRMED_COUNT_FAILED');
export const UnconfirmedCountClear      = createAction('UNCONFIRMED_COUNT_CLEAR');

export const ConfirmInvoiceRequest      = createAction('CONFIRM_INVOICE_REQUEST');
export const ConfirmInvoiceSuccess      = createAction('CONFIRM_INVOICE_SUCCESS');
export const ConfirmInvoiceFailed       = createAction('CONFIRM_INVOICE_FAILED');
export const ConfirmInvoiceClear        = createAction('CONFIRM_INVOICE_CLEAR');
export const SaveConfirmInvoice         = createAction('SAVE_CONFIRM_INVOICE');
export const SaveConfirmInvoiceClear    = createAction('SAVE_CONFIRM_INVOICE_CLEAR');

export const PaidInvoiceRequest         = createAction('PAID_INVOICE_REQUEST');
export const PaidInvoiceSuccess         = createAction('PAID_INVOICE_SUCCESS');
export const PaidInvoiceFailed          = createAction('PAID_INVOICE_FAILED');
export const PaidInvoiceClear           = createAction('PAID_INVOICE_CLEAR');

export const UpdateInvoiceRequest       = createAction('UPDATE_INVOICE_REQUEST');
export const UpdateInvoiceSuccess       = createAction('UPDATE_INVOICE_SUCCESS');
export const UpdateInvoiceFailed        = createAction('UPDATE_INVOICE_FAILED');
export const UpdateInvoiceClear         = createAction('UPDATE_INVOICE_CLEAR');

