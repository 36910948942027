import {createAction} from "redux-actions";

export const ListCollectRequest = createAction('LIST_COLLECT_REQUEST');
export const ListCollectSuccess = createAction('LIST_COLLECT_SUCCESS');
export const ListCollectFailed = createAction('LIST_COLLECT_FAILED');
export const ListCollectClear = createAction('LIST_COLLECT_CLEAR');

export const DetailCollectRequest       = createAction('DETAIL_COLLECT_REQUEST');
export const DetailCollectSuccess       = createAction('DETAIL_COLLECT_SUCCESS');
export const DetailCollectFailed        = createAction('DETAIL_COLLECT_FAILED');
export const DetailCollectClear         = createAction('DETAIL_COLLECT_CLEAR');

export const ExportCsvCollectRequest    = createAction('EXPORT_CSV_COLLECT_REQUEST');
export const ExportCsvCollectSuccess    = createAction('EXPORT_CSV_COLLECT_SUCCESS');
export const ExportCsvCollectFailed     = createAction('EXPORT_CSV_COLLECT_FAILED');
export const ExportCsvCollectClear      = createAction('EXPORT_CSV_COLLECT_CLEAR');

export const CancelCollectRequest    = createAction('CANCEL_COLLECT_REQUEST');
export const CancelCollectSuccess    = createAction('CANCEL_COLLECT_SUCCESS');
export const CancelCollectFailed     = createAction('CANCEL_COLLECT_FAILED');
export const CancelCollectClear      = createAction('CANCEL_COLLECT_CLEAR');

export const DeliveredCollectRequest    = createAction('DELIVERED_COLLECT_REQUEST');
export const DeliveredCollectSuccess    = createAction('DELIVERED_COLLECT_SUCCESS');
export const DeliveredCollectFailed     = createAction('DELIVERED_COLLECT_FAILED');
export const DeliveredCollectClear      = createAction('DELIVERED_COLLECT_CLEAR');
