import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Membership';

const listMembershipReducer = handleActions(
    {
        [Actions.ListMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailMembershipReducer = handleActions(
    {
        [Actions.DetailMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const createMembershipReducer = handleActions(
    {
        [Actions.CreateMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CreateMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CreateMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors || {
                message: '予期しないエラーが発生しました',
                code: 500,
            },
        }),
        [Actions.CreateMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateMembershipReducer = handleActions(
    {
        [Actions.UpdateMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
            isSuccess: false,
        }),
        [Actions.UpdateMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors || {
                message: '予期しないエラーが発生しました',
                code: 500,
            },
        }),
        [Actions.UpdateMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const deleteMembershipReducer = handleActions(
    {
        [Actions.DeleteMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DeleteMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DeleteMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DeleteMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const resetPasswordMembershipReducer = handleActions(
    {
        [Actions.ResetPasswordMembershipRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ResetPasswordMembershipSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ResetPasswordMembershipFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ResetPasswordMembershipClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listMembershipReducer,
    detail: detailMembershipReducer,
    create: createMembershipReducer,
    update: updateMembershipReducer,
    delete: deleteMembershipReducer,
    resetpw: resetPasswordMembershipReducer,
});
