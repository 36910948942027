import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Chip,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItem';

// project imports
import { MENU_OPEN, SET_MENU } from '../../../../actions/customizationAction';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// style constant
const useStyles = makeStyles(() => ({
    listIcon: {
        minWidth: '18px',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    listCustomIconSub: {
        width: '6px',
        height: '6px',
    },
    listCustomIconSubActive: {
        width: '8px',
        height: '8px',
    },
    listItem: {
        marginBottom: '5px',
        alignItems: 'center',
    },
    listItemNoBack: {
        marginBottom: '5px',
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'flex-start',
    },
    subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: '#9e9e9e',
        textTransform: 'capitalize',
    },
    listNonActive: {
        pointerEvents: 'none',
    },
}));

// ===========================|| SIDEBAR MENU LIST ITEMS ||=========================== //

const NavItem = ({ item, level, collapseItem }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const router = useSelector((state) => state.router.location.pathname);
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const itemIcon = !collapseItem ? (
        <></>
    ) : (
        <FiberManualRecordIcon
            className={
                customization.isOpen.findIndex((id) => id === item.id) > -1
                    ? classes.listCustomIconSubActive
                    : classes.listCustomIconSub
            }
            fontSize={level > 0 ? 'inherit' : 'default'}
        />
    );

    let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
    itemIconClass = [itemIconClass, classes.listCustomIcon].join(' ');
    customization.navType === 'nav-dark'
        ? [itemIconClass, classes.listCustomIcon].join(' ')
        : itemIconClass;

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        // eslint-disable-next-line react/display-name
        component: React.forwardRef((props, ref) => {
            if (item.url === '/')
                return (
                    <Link
                        ref={ref}
                        {...props}
                        to='/'
                        style={{
                            paddingLeft: `${level * 23}px`,
                            borderRadius: '12px',
                            pointerEvents: 'none',
                            color: '#9e9e9e',
                        }}
                    />
                );
            return <Link ref={ref} {...props} to={item.url} />;
        }),
    };
    if (item.external) {
        listItemProps = { component: 'a', href: item.url };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesMd) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
    }, [router]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            className={level > 1 ? classes.listItemNoBack : classes.listItem}
            selected={
                customization.isOpen.findIndex((id) => id === item.id) > -1
            }
            onClick={() => itemHandler(item.id)}
            target={itemTarget}
            style={{ paddingLeft: `${level * 23}px`, borderRadius: '12px' }}
            button
        >
            {collapseItem && (
                <ListItemIcon className={itemIconClass}>
                    {itemIcon}
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography variant='subtitle1' color='inherit'>
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography
                            variant='caption'
                            className={classes.subMenuCaption}
                            display='block'
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={
                        item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                    }
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    collapseItem: PropTypes.bool,
};

export default NavItem;
