import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Box';
import { waitAuthorizedCheck } from './Auth';

function* getListBox(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: `/api/account/${action.payload.account_id}/boxes`,
            body: { check: action.payload.check || false },
            token: credential.data.access_token,
        });
        yield put(Actions.ListBoxSuccess(response));
    } catch (error) {
        yield put(Actions.ListBoxFailed(error));
    }
}

export default function* Box() {
    yield all([takeLatest('LIST_BOX_REQUEST', getListBox)]);
}
