import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, useMediaQuery } from '@material-ui/core';

import ProfileSection from '../ProfileSection';
// import NotificationSection from '../NotificationSection';
import { MENU_OPEN, SET_MENU } from '../../actions/customizationAction';
import { IconMenu2 } from '@tabler/icons';
import LogoWeb1 from '../../assets/images/logo_web1.svg';

// style constant
const useStyles = makeStyles((theme) => ({
    logo_section: {
        display: 'block',
        flexGrow: 1,
        fontSize: 20,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    headerAvatar: {
        cursor: 'pointer',
        borderRadius: '8px',
        width: '34px',
        height: '34px',
        fontSize: '1.2rem',
        transition: 'all .2s ease-in-out',
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.light,
        },
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        },
    },
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const itemHandler = (link, id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesMd) dispatch({ type: SET_MENU, opened: false });
        history.push(link);
    };

    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box
                    component='span'
                    className={classes.logo_section}
                    onClick={() => itemHandler('/admin/order', 'order')}
                >
                    {/* <Typography variant='h3'>宅配クローゼット</Typography> */}
                    <img
                        src={LogoWeb1}
                        alt='logo-dashboard'
                        style={{ width: 170 }}
                    />
                </Box>
                <ButtonBase
                    style={{ borderRadius: '12px', overflow: 'hidden' }}
                >
                    <Avatar
                        variant='rounded'
                        className={classes.headerAvatar}
                        onClick={handleLeftDrawerToggle}
                        color='inherit'
                    >
                        <IconMenu2 stroke={1.5} size='1.3rem' />
                    </Avatar>
                </ButtonBase>
            </div>

            {/* header search */}
            {/* <SearchSection theme="light" /> */}
            <div className={classes.grow} />
            <div className={classes.grow} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
