import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Account';

const listAccountReducer = handleActions(
    {
        [Actions.ListAccountRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListAccountSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListAccountFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListAccountClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailAccountReducer = handleActions(
    {
        [Actions.DetailAccountRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailAccountSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailAccountFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailAccountClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateAccountReducer = handleActions(
    {
        [Actions.UpdateAccountRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateAccountSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateAccountFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateAccountClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const deleteAccountReducer = handleActions(
    {
        [Actions.DeleteAccountRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DeleteAccountSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DeleteAccountFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DeleteAccountClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const checkDeleteReducer = handleActions(
    {
        [Actions.CheckDeleteRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CheckDeleteSuccess]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            errors: {},
        }),
        [Actions.CheckDeleteFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CheckDeleteClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        errors: {},
    }
);

export default combineReducers({
    list: listAccountReducer,
    detail: detailAccountReducer,
    update: updateAccountReducer,
    delete: deleteAccountReducer,
    check_delete: checkDeleteReducer,
});
