import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Global';

const createGlobalReducer = handleActions(
    {
        [Actions.GetListGroupRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.GetListGroupSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: action.payload,
            errors: {},
        }),
        [Actions.GetListGroupFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.GetListGroupClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const createCategoryReducer = handleActions(
    {
        [Actions.GetListCategoryRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.GetListCategorySuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: action.payload,
            errors: {},
        }),
        [Actions.GetListCategoryFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.GetListCategoryFailed]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    group: createGlobalReducer,
    category: createCategoryReducer,
});
