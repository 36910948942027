import { createAction } from 'redux-actions';

export const ListAccountRequest         = createAction('LIST_ACCOUNT_REQUEST');
export const ListAccountSuccess         = createAction('LIST_ACCOUNT_SUCCESS');
export const ListAccountFailed          = createAction('LIST_ACCOUNT_FAILED');
export const ListAccountClear           = createAction('LIST_ACCOUNT_CLEAR');

export const DetailAccountRequest       = createAction('DETAIL_ACCOUNT_REQUEST');
export const DetailAccountSuccess       = createAction('DETAIL_ACCOUNT_SUCCESS');
export const DetailAccountFailed        = createAction('DETAIL_ACCOUNT_FAILED');
export const DetailAccountClear         = createAction('DETAIL_ACCOUNT_CLEAR');

export const UpdateAccountRequest       = createAction('UPDATE_ACCOUNT_REQUEST');
export const UpdateAccountSuccess       = createAction('UPDATE_ACCOUNT_SUCCESS');
export const UpdateAccountFailed        = createAction('UPDATE_ACCOUNT_FAILED');
export const UpdateAccountClear         = createAction('UPDATE_ACCOUNT_CLEAR');

export const DeleteAccountRequest       = createAction('DELETE_ACCOUNT_REQUEST');
export const DeleteAccountSuccess       = createAction('DELETE_ACCOUNT_SUCCESS');
export const DeleteAccountFailed        = createAction('DELETE_ACCOUNT_FAILED');
export const DeleteAccountClear         = createAction('DELETE_ACCOUNT_CLEAR');

export const CheckDeleteRequest         = createAction('CHECK_DELETE_REQUEST');
export const CheckDeleteSuccess         = createAction('CHECK_DELETE_SUCCESS');
export const CheckDeleteFailed          = createAction('CHECK_DELETE_FAILED');
export const CheckDeleteClear           = createAction('CHECK_DELETE_CLEAR');