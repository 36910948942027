import { call } from 'redux-saga/effects';
import API from '../service/API';
import { waitAuthorizedCheck } from './Auth';

export function* generatePresignedUrl(file) {
    const credential = yield call(waitAuthorizedCheck);
    const fileName = file.name;

    try {
        const response = yield call(API.postGeneratePresignedUrl, {
            endpoint: '/api/s3/generatePresignedUrls',
            body: [fileName],
            token: credential.data.access_token,
        });

        yield call(API.putGeneratePresignedUrl, {
            endpoint: response[0].upload_presigned_url,
            body: file,
            token: credential.data.access_token,
        });

        return response[0].download_url;
    } catch (error) {
        console.log('generatePresignedUrl Error: ', error);
    }
}
