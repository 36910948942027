import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';

import reducers from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'global'],
    transforms: [
        expireReducer(['auth', 'global'], {
            expireSeconds: 60 * 60,
            expiredState: {},
            autoExpire: true,
        }),
    ],
};

const history = createBrowserHistory({ basename: '/dcs' });

const middlewares = [];

if (process.env.REACT_APP_APPLY_REDUX_LOGGER) {
    middlewares.push(logger);
}

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

middlewares.push(routerMiddleware(history));

let composeEnhancers = compose;
if (
    process.env.REACT_APP_APPLY_REDUX_DEVTOOLS &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
    });
}

const persistedReducer = persistReducer(persistConfig, reducers(history));

let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
);

let persistor = persistStore(store);

export { store, persistor, sagaMiddleware, history };
