import { createAction } from 'redux-actions';

/* Action for index data master */
export const DataCategoriesRequest               = createAction('DATA_CATEGORIES_REQUEST');
export const DataCategoriesSuccess               = createAction('DATA_CATEGORIES_SUCCESS');
export const DataCategoriesFailed                = createAction('DATA_CATEGORIES_FAILED');
export const DataCategoriesClear                 = createAction('DATA_CATEGORIES_CLEAR');

export const DataBoxRequest                      = createAction('DATA_BOX_REQUEST');
export const DataBoxSuccess                      = createAction('DATA_BOX_SUCCESS');
export const DataBoxFailed                       = createAction('DATA_BOX_FAILED');
export const DataBoxClear                        = createAction('DATA_BOX_CLEAR');

export const DataBoxReturnRequest                = createAction('DATA_BOX_RETURN_REQUEST');
export const DataBoxReturnSuccess                = createAction('DATA_BOX_RETURN_SUCCESS');
export const DataBoxReturnFailed                 = createAction('DATA_BOX_RETURN_FAILED');
export const DataBoxReturnClear                  = createAction('DATA_BOX_RETURN_CLEAR');

export const DataServicePriceRequest             = createAction('DATA_SERVICE_PRICE_REQUEST');
export const DataServicePriceSuccess             = createAction('DATA_SERVICE_PRICE_SUCCESS');
export const DataServicePriceFailed              = createAction('DATA_SERVICE_PRICE_FAILED');
export const DataServicePriceClear               = createAction('DATA_SERVICE_PRICE_CLEAR');

/* Action for table edit data master */
export const DialogDataCategoriesRequest         = createAction('DIALOG_DATA_CATEGORIES_REQUEST');
export const DialogDataCategoriesSuccess         = createAction('DIALOG_DATA_CATEGORIES_SUCCESS');
export const DialogDataCategoriesFailed          = createAction('DIALOG_DATA_CATEGORIES_FAILED');
export const DialogDataCategoriesClear           = createAction('DIALOG_DATA_CATEGORIES_CLEAR');

export const DialogDataBoxRequest                = createAction('DIALOG_DATA_BOX_REQUEST');
export const DialogDataBoxSuccess                = createAction('DIALOG_DATA_BOX_SUCCESS');
export const DialogDataBoxFailed                 = createAction('DIALOG_DATA_BOX_FAILED');
export const DialogDataBoxClear                  = createAction('DIALOG_DATA_BOX_CLEAR');

export const DialogDataBoxReturnRequest          = createAction('DIALOG_DATA_BOX_RETURN_REQUEST');
export const DialogDataBoxReturnSuccess          = createAction('DIALOG_DATA_BOX_RETURN_SUCCESS');
export const DialogDataBoxReturnFailed           = createAction('DIALOG_DATA_BOX_RETURN_FAILED');
export const DialogDataBoxReturnClear            = createAction('DIALOG_DATA_BOX_RETURN_CLEAR');

export const DialogDataServicePriceRequest       = createAction('DIALOG_DATA_SERVICE_PRICE_REQUEST');
export const DialogDataServicePriceSuccess       = createAction('DIALOG_DATA_SERVICE_PRICE_SUCCESS');
export const DialogDataServicePriceFailed        = createAction('DIALOG_DATA_SERVICE_PRICE_FAILED');
export const DialogDataServicePriceClear         = createAction('DIALOG_DATA_SERVICE_PRICE_CLEAR');

/* Action for update data master */
export const UpdateCategoriesRequest             = createAction('UPDATE_CATEGORIES_REQUEST');
export const UpdateCategoriesSuccess             = createAction('UPDATE_CATEGORIES_SUCCESS');
export const UpdateCategoriesFailed              = createAction('UPDATE_CATEGORIES_FAILED');
export const UpdateCategoriesClear               = createAction('UPDATE_CATEGORIES_CLEAR');

export const UpdateBoxRequest                    = createAction('UPDATE_BOX_REQUEST');
export const UpdateBoxSuccess                    = createAction('UPDATE_BOX_SUCCESS');
export const UpdateBoxFailed                     = createAction('UPDATE_BOX_FAILED');
export const UpdateBoxClear                      = createAction('UPDATE_BOX_CLEAR');

export const UpdateBoxReturnRequest              = createAction('UPDATE_BOX_RETURN_REQUEST');
export const UpdateBoxReturnSuccess              = createAction('UPDATE_BOX_RETURN_SUCCESS');
export const UpdateBoxReturnFailed               = createAction('UPDATE_BOX_RETURN_FAILED');
export const UpdateBoxReturnClear                = createAction('UPDATE_BOX_RETURN_CLEAR');

export const UpdateServicePriceRequest           = createAction('UPDATE_SERVICE_PRICE_REQUEST');
export const UpdateServiceHangerSuccess          = createAction('UPDATE_SERVICE_HANGER_SUCCESS');
export const UpdateServiceCleaningSuccess        = createAction('UPDATE_SERVICE_CLEANING_SUCCESS');
export const UpdateServiceShoeRepairSuccess      = createAction('UPDATE_SERVICE_SHOE_REPAIR_SUCCESS');
export const UpdateServiceInsuranceSuccess       = createAction('UPDATE_SERVICE_INSURANCE_SUCCESS');
export const UpdateServicePriceFailed            = createAction('UPDATE_SERVICE_PRICE_FAILED');
export const UpdateServicePriceClear             = createAction('UPDATE_SERVICE_PRICE_CLEAR');