import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/ShoeRepair';
import { waitAuthorizedCheck } from './Auth';

function* getListShoeRepair(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/service/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'createdDate,desc'}`;
    let bodyRequest = { status: 'NEW', service_ids: [10] };
    if (action.payload?.body)
        bodyRequest = { ...bodyRequest, ...action.payload.body };
    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListShoeRepairSuccess(response));
    } catch (error) {
        yield put(Actions.ListShoeRepairFailed(error));
    }
}

function* getDetailShoeRepair(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/service/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailShoeRepairSuccess(response));
    } catch (error) {
        yield put(Actions.DetailShoeRepairFailed(error));
    }
}

function* getServiceOptions(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/service/options?serviceId=10`,
            token: credential.data.access_token,
            body: action.payload,
        });
        yield put(Actions.GetServiceOptionsSuccess(response));
    } catch (error) {
        yield put(Actions.GetServiceOptionsFailed(error));
    }
}

function* getServicePrices(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: '/api/admin/order/service/prices',
            token: credential.data.access_token,
            body: action.payload,
        });
        yield put(Actions.GetServicePricesSuccess(response));
    } catch (error) {
        yield put(Actions.GetServicePricesFailed(error));
    }
}

function* processShoeRepair(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload.shoeRepairId}/process`,
            body: { ...action.payload.body },
            token: credential.data.access_token,
        });
        yield put(Actions.ProcessShoeRepairSuccess(response));
    } catch (error) {
        yield put(Actions.ProcessShoeRepairFailed(error));
    }
}

function* cancelShoeRepair(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload.orderId}/cancel`,
            token: credential.data.access_token,
        });
        yield put(Actions.CancelShoeRepairSuccess(response));
    } catch (error) {
        yield put(Actions.CancelShoeRepairFailed(error));
    }
}

function* completeShoeRepair(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload.orderId}/complete`,
            body: {},
            token: credential.data.access_token,
        });
        yield put(Actions.CompleteShoeRepairSuccess(response));
    } catch (error) {
        yield put(Actions.CompleteShoeRepairFailed(error));
    }
}

export default function* ShoeRepair() {
    yield all([
        takeLatest('LIST_SHOE_REPAIR_REQUEST', getListShoeRepair),
        takeLatest('DETAIL_SHOE_REPAIR_REQUEST', getDetailShoeRepair),
        takeLatest('GET_SERVICE_OPTIONS_REQUEST', getServiceOptions),
        takeLatest('GET_SERVICE_PRICES_REQUEST', getServicePrices),
        takeLatest('PROCESS_SHOE_REPAIR_REQUEST', processShoeRepair),
        takeLatest('CANCEL_SHOE_REPAIR_REQUEST', cancelShoeRepair),
        takeLatest('COMPLETE_SHOE_REPAIR_REQUEST', completeShoeRepair),
    ]);
}
