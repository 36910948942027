import queryString from 'query-string';
import axios from 'axios';
import { APIErrors } from '../constants/APIErrors';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_BASE;

// parse blob to json ( can reuse )
// const fileReader = (file) => {
//     const fileReader = new FileReader();

//     return new Promise((resolve, reject) => {
//         fileReader.onerror = () => {
//             fileReader.abort();
//             reject(new Error('Problem parsing file'));
//         };

//         fileReader.onload = () => {
//             resolve(fileReader.result);
//         };

//         fileReader.readAsText(file, 'UTF-8');
//     });
// };

const handleResponseSuccess = (response) =>
    new Promise((resolve) => resolve(response.data));

const handleErrors = (response) =>
    new Promise((resolve, reject) => {
        switch (response.status) {
            case 400:
                return reject(handleBadRequestError(response.data));
            case 401:
                return reject(API.specifyError(APIErrors.authExpired));
            case 403:
                return reject(API.specifyError(APIErrors.permissionDenied));
            case 404:
            case 500:
                return reject(API.specifyError(APIErrors.server));
            case 503:
                return reject(API.specifyError(APIErrors.maintenance));
            default:
                return reject(API.specifyError(APIErrors.server));
        }
    });

const handleBadRequestError = (parsedJson) => {
    let resolveObject = {
        status: APIErrors.badRequest,
    };

    return {
        errors: {
            ...resolveObject,
            ...parsedJson,
        },
    };
};

const API = {
    /**
     * Login
     */
    login: async ({ endpoint, body }) => {
        let response;
        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeaderWithoutToken(),
                data: JSON.stringify({ ...body }),
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * POST method
     */
    post: async ({ endpoint, body, token = '' }) => {
        let response;

        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeader(token),
                data: JSON.stringify({ ...body }),
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    postArray: async ({ endpoint, body, token = '' }) => {
        let response;

        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeader(token),
                data: body,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    postGeneratePresignedUrl: async ({ endpoint, body = [], token = '' }) => {
        let response;

        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeader(token),
                data: body,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    postDownload: async ({
        endpoint,
        body = [],
        token = '',
        filename = 'file.csv',
    }) => {
        let response;

        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeader(token),
                data: body,
            });
            const downloadLink = document.createElement('a');
            const blob = new Blob(['\ufeff', response.data]);
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * POST with file
     */
    postWithFile: async ({ endpoint, body, token = '', onProgress }) => {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
            formData.append(key, body[key]);
        });
        let response;
        try {
            response = await axios({
                method: 'POST',
                url: endpoint,
                headers: API.buildHeaderWithFile(token),
                data: formData,
                onUploadProgress: onProgress,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * PUT method
     */
    put: async ({ endpoint, body, token = '' }) => {
        let response;
        try {
            response = await axios({
                method: 'PUT',
                url: endpoint,
                headers: API.buildHeader(token),
                data: JSON.stringify({ ...body }),
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    putArray: async ({ endpoint, body, token = '' }) => {
        let response;

        try {
            response = await axios({
                method: 'PUT',
                url: endpoint,
                headers: API.buildHeader(token),
                data: body,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    putGeneratePresignedUrl: async ({ endpoint, body }) => {
        let response;

        try {
            response = await axios({
                method: 'PUT',
                url: endpoint,
                data: body,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * PUT with file method
     */
    putWithFile: async ({ endpoint, body, token = '', onProgress }) => {
        let response;
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
            formData.append(key, body[key]);
        });
        try {
            response = await axios({
                method: 'PUT',
                url: endpoint,
                headers: API.buildHeaderWithFile(token),
                data: formData,
                onUploadProgress: onProgress,
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * DELETE method
     */
    delete: async ({ endpoint, token = '' }) => {
        let response;

        try {
            response = await axios({
                method: 'DELETE',
                url: endpoint,
                headers: API.buildHeader(token),
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * GET method
     */
    get: async ({ endpoint, body, token = '' }) => {
        let response;
        const query = queryString.stringify(body, { arrayFormat: 'comma' });
        let apiUrl = endpoint;

        if (query) {
            apiUrl = `${endpoint}?${query}`;
        }

        try {
            response = await axios({
                method: 'GET',
                url: apiUrl,
                headers: API.buildHeader(token),
            });
            return handleResponseSuccess(response);
        } catch (error) {
            return handleErrors(error.response);
        }
    },

    /**
     * POST header without token
     */
    buildHeaderWithoutToken: () => ({
        'content-type': 'application/json; utf-8',
        'api-key': process.env.REACT_APP_API_KEY,
    }),

    /**
     * POST PUT GET header
     * @requestToken api token
     */
    buildHeader: (token) => {
        const headers = {
            'content-Type': 'application/json; utf-8',
            'api-key': process.env.REACT_APP_API_KEY,
        };
        if (token) {
            return { ...headers, Authorization: `Bearer ${token}` };
        }
        return headers;
    },

    /**
     * POST header with file
     * @requstToken api token
     */
    buildHeaderWithFile: (token) => {
        const headers = { 'content-type': 'multipart/form-data' };
        if (token) {
            return { ...headers, Authorization: `Bearer ${token}` };
        }
        return headers;
    },

    /**
     * GET no token header
     */
    buildGetHeader: () => ({ 'content-type': 'text/plain; utf-8' }),

    /**
     * @code string
     * @msg string
     */
    specifyError: (code, message) => ({
        errors: {
            code,
            message,
        },
    }),
};

export default API;
