import { PERMISSION } from '../constants/Permission';
// ===========================|| USER MANAGEMENT MENU ITEMS ||=========================== //

const AdminDashboard = {
    id: 'AdminDashboard',
    title: 'ダッシュボード管理',
    type: 'group',
    children: [
        {
            // Quản lý hàng order
            id: 'order',
            title: 'お取り寄せ管理',
            type: 'item',
            url: '/admin/order',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_BOX_ORDER,
        },
        {
            // Quản lý gom hàng
            id: 'collected',
            title: '集荷管理',
            type: 'item',
            url: '/admin/collected',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_COLLECTED_ORDER,
        },
        {
            // Quản lý box
            id: 'box',
            title: 'ボックス管理',
            type: 'item',
            url: '/admin/box',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_BOX,
        },
        {
            // Quản lý item
            id: 'item',
            title: 'アイテム管理',
            type: 'item',
            url: '/admin/item',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_BOX_ITEM,
        },
        {
            // Quản lý lấy ra
            id: 'request',
            title: '取り出し管理',
            type: 'item',
            url: '/admin/request',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_RETURN_ORDER,
        },
        {
            // Quản lý Hanger
            id: 'service-option',
            title: 'オプション管理',
            type: 'collapse',
            breadcrumbs: false,
            children: [
                {
                    id: 'hanger',
                    title: 'ハンガー保管依頼管理',
                    type: 'item',
                    url: '/admin/hanger',
                    breadcrumbs: false,
                },
                {
                    id: 'cleaning',
                    title: 'クリーニング依頼管理',
                    type: 'item',
                    url: '/admin/cleaning',
                    breadcrumbs: false,
                },
                {
                    id: 'shoe-repair',
                    title: 'シューズリペア依頼管理',
                    type: 'item',
                    url: '/admin/shoe-repair',
                    breadcrumbs: false,
                },
            ],
        },
        {
            // Quản lý hội viên
            id: 'account',
            title: '会員管理',
            type: 'item',
            url: '/admin/account',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_MEMBERSHIP,
        },
        {
            // Quản lý thanh toán/tiền nhập
            id: 'invoice',
            title: '支払・入金管理',
            type: 'item',
            url: '/admin/invoice',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_INVOICE,
        },
        {
            // Quản lý Thông báo
            id: 'notification',
            title: 'お知らせ',
            type: 'item',
            url: '/admin/notification',
            breadcrumbs: false,
            permissions: PERMISSION.LIST_BROADCAST_NOTIFICATION,
        },
        {
            // Quản lý data
            id: 'data-master',
            title: 'データ管理',
            type: 'item',
            url: '/admin/data-master',
            breadcrumbs: false,
            permissions: PERMISSION.SUPER,
        },
    ],
};

export default AdminDashboard;
