import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Account';
import { waitAuthorizedCheck } from './Auth';

function* getListAccount(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/account/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'id,asc'}`;
    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...action.payload?.body },
            token: credential.data.access_token,
        });
        yield put(Actions.ListAccountSuccess(response));
    } catch (error) {
        yield put(Actions.ListAccountFailed(error));
    }
}

function* getDetailAccount(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/account/${action.payload}`,
            token: credential.data.access_token,
        });

        yield put(Actions.DetailAccountSuccess(response));
    } catch (error) {
        yield put(Actions.DetailAccountFailed(error));
    }
}

function* deleteAccount(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.delete, {
            endpoint: `/api/account/${action.payload}`,
            token: credential.data.access_token,
        });

        yield put(Actions.DeleteAccountSuccess(response));
    } catch (error) {
        yield put(Actions.DeleteAccountFailed(error));
    }
}

function* checkDeleteAccount(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/account/${action.payload}/remove/check`,
            token: credential.data.access_token,
        });

        yield put(Actions.CheckDeleteSuccess(response));
    } catch (error) {
        yield put(Actions.CheckDeleteFailed(error));
    }
}

export default function* Account() {
    yield all([
        takeLatest('LIST_ACCOUNT_REQUEST', getListAccount),
        takeLatest('DETAIL_ACCOUNT_REQUEST', getDetailAccount),
        takeLatest('DELETE_ACCOUNT_REQUEST', deleteAccount),
        takeLatest('CHECK_DELETE_REQUEST', checkDeleteAccount),
    ]);
}
