import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Collect';

const listCollectReducer = handleActions(
    {
        [Actions.ListCollectSuccess]: (state, action) => ({
            ...state,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListCollectFailed]: (state, action) => ({
            ...state,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListCollectClear]: (state) => ({
            ...state,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);
const detailCollectReducer = handleActions(
    {
        [Actions.DetailCollectRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailCollectSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailCollectFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailCollectClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const exportCsvReducer = handleActions(
    {
        [Actions.ExportCsvCollectRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ExportCsvCollectSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ExportCsvCollectFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ExportCsvCollectClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const cancelCollectReducer = handleActions(
    {
        [Actions.CancelCollectRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CancelCollectSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CancelCollectFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CancelCollectClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const deliveredCollectReducer = handleActions(
    {
        [Actions.DeliveredCollectRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DeliveredCollectSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DeliveredCollectFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DeliveredCollectClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listCollectReducer,
    detail: detailCollectReducer,
    exportcsv: exportCsvReducer,
    cancel: cancelCollectReducer,
    delivered: deliveredCollectReducer,
});
