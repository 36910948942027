import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Order';
import { waitAuthorizedCheck } from './Auth';
import format from 'date-fns/format';

function* getListOrder(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/box/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'orderDate,desc'}`;
    let bodyRequest = { status: 'NEW' };
    if (action.payload?.body)
        bodyRequest = { ...bodyRequest, ...action.payload.body };

    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListOrderSuccess(response));
    } catch (error) {
        yield put(Actions.ListOrderFailed(error));
    }
}

function* getDetailOrder(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/box/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailOrderSuccess(response));
    } catch (error) {
        yield put(Actions.DetailOrderFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/order/box/process?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            body: action.payload.orderIds ?? [],
            filename: `ボックスお取り寄せ依頼_${format(
                new Date(),
                'yyyyMMdd'
            )}.csv`,
        });
        yield put(Actions.ExportCsvOrderSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvOrderFailed(error));
    }
}

function* cancelOrder(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/box/${action.payload}/cancel`,
            token: credential.data.access_token,
        });
        yield put(Actions.CancelOrderSuccess(response));
    } catch (error) {
        yield put(Actions.CancelOrderFailed(error));
    }
}

function* deliveredOrder(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/box/${action.payload.order_id}/complete`,
            body: {
                code: action.payload.code,
            },
            token: credential.data.access_token,
        });
        yield put(Actions.DeliveredOrderSuccess(response));
    } catch (error) {
        yield put(Actions.DeliveredOrderFailed(error));
    }
}

export default function* Order() {
    yield all([
        takeLatest('LIST_ORDER_REQUEST', getListOrder),
        takeLatest('DETAIL_ORDER_REQUEST', getDetailOrder),
        takeLatest('EXPORT_CSV_ORDER_REQUEST', exportCsv),
        takeLatest('CANCEL_ORDER_REQUEST', cancelOrder),
        takeLatest('DELIVERED_ORDER_REQUEST', deliveredOrder),
    ]);
}
