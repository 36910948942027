import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Order';

const listOrderReducer = handleActions(
    {
        [Actions.ListOrderRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListOrderSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListOrderFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListOrderClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        status: '',
        errors: {},
    }
);

const detailOrderReducer = handleActions(
    {
        [Actions.DetailOrderRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailOrderSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailOrderFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailOrderClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const exportCsvReducer = handleActions(
    {
        [Actions.ExportCsvOrderRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ExportCsvOrderSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ExportCsvOrderFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ExportCsvOrderClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const cancelOrderReducer = handleActions(
    {
        [Actions.CancelOrderRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CancelOrderSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CancelOrderFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CancelOrderClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const deliveredOrderReducer = handleActions(
    {
        [Actions.DeliveredOrderRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DeliveredOrderSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DeliveredOrderFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DeliveredOrderClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listOrderReducer,
    detail: detailOrderReducer,
    exportcsv: exportCsvReducer,
    cancel: cancelOrderReducer,
    delivered: deliveredOrderReducer,
});
