import { createAction } from "redux-actions";

export const ListHangerRequest                = createAction('LIST_HANGER_REQUEST');
export const ListHangerSuccess                = createAction('LIST_HANGER_SUCCESS');
export const ListHangerFailed                 = createAction('LIST_HANGER_FAILED');
export const ListHangerClear                  = createAction('LIST_HANGER_CLEAR');

export const ExportCsvHangerRequest           = createAction('EXPORT_CSV_HANGER_REQUEST');
export const ExportCsvHangerSuccess           = createAction('EXPORT_CSV_HANGER_SUCCESS');
export const ExportCsvHangerFailed             = createAction('EXPORT_CSV_HANGER_FAILED');
export const ExportCsvHangerClear             = createAction('EXPORT_CSV_HANGER_CLEAR');

export const DetailHangerRequest              = createAction('DETAIL_HANGER_REQUEST');
export const DetailHangerSuccess              = createAction('DETAIL_HANGER_SUCCESS');
export const DetailHangerFailed               = createAction('DETAIL_HANGER_FAILED');
export const DetailHangerClear                = createAction('DETAIL_HANGER_CLEAR');

export const RegisterHangerRequest            = createAction('REGISTER_HANGER_REQUEST');
export const RegisterHangerSuccess            = createAction('REGISTER_HANGER_SUCCESS');
export const RegisterHangerFailed             = createAction('REGISTER_HANGER_FAILED');
export const RegisterHangerClear              = createAction('REGISTER_HANGER_CLEAR');

export const CancelHangerRequest              = createAction('CANCEL_HANGER_REQUEST');
export const CancelHangerSuccess              = createAction('CANCEL_HANGER_SUCCESS');
export const CancelHangerFailed               = createAction('CANCEL_HANGER_FAILED');
export const CancelHangerClear                = createAction('CANCEL_HANGER_CLEAR');

export const CountHangerProcessingRequest     = createAction('COUNT_HANGER_PROCESSING_REQUEST');
export const CountHangerProcessingSuccess     = createAction('COUNT_HANGER_PROCESSING_SUCCESS');
export const CountHangerProcessingFailed      = createAction('COUNT_HANGER_PROCESSING_FAILED');
export const CountHangerProcessingClear       = createAction('COUNT_HANGER_PROCESSING_CLEAR');