import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { MobileView } from 'react-device-detect';

import MainLayout from './layout/MainLayout';

import Loadable from './components/Load/Loadable';

// WebView
const ResetPassword = Loadable(
    lazy(() => import('./views/WebView/ResetPassword'))
);
const ConfirmResetPassword = Loadable(
    lazy(() => import('./views/WebView/ConfirmResetPassword'))
);

const Login = Loadable(lazy(() => import('./views/Login/Login')));
const OrderManagement = Loadable(lazy(() => import('./views/Order')));
const OrderDetail = Loadable(lazy(() => import('./views/Order/Detail')));
const CollectManagement = Loadable(lazy(() => import('./views/Collect')));
const DetailCollect = Loadable(lazy(() => import('./views/Collect/Detail')));
const AccountManagement = Loadable(lazy(() => import('./views/Account')));
const AccountDetail = Loadable(lazy(() => import('./views/Account/Detail')));
const BoxManagement = Loadable(lazy(() => import('./views/Box')));
const DetailBoxManagement = Loadable(lazy(() => import('./views/Box/Detail')));
const MembershipManagement = Loadable(lazy(() => import('./views/Membership')));
const ExtendBox = Loadable(lazy(() => import('./views/Box/ExtendBox')));
const MembershipDetail = Loadable(
    lazy(() => import('./views/Membership/Detail'))
);
const ItemManagement = Loadable(lazy(() => import('./views/Item')));
const ItemDetail = Loadable(lazy(() => import('./views/Item/Detail')));
const RequestManagement = Loadable(lazy(() => import('./views/Request')));
const RequestDetail = Loadable(lazy(() => import('./views/Request/Detail')));
const InvoiceManagement = Loadable(lazy(() => import('./views/Invoice')));
const Notification = Loadable(lazy(() => import('./views/Notification')));
const DetailNotification = Loadable(
    lazy(() => import('./views/Notification/Detail'))
);
const DataMaster = Loadable(lazy(() => import('./views/DataMaster')));
const Hanger = Loadable(lazy(() => import('./views/Hanger')));
const HangerDetail = Loadable(lazy(() => import('./views/Hanger/Detail')));
const ShoeRepair = Loadable(lazy(() => import('./views/ShoeRepair')));
const ShoeRepairDetail = Loadable(
    lazy(() => import('./views/ShoeRepair/Detail'))
);
const Cleaning = Loadable(lazy(() => import('./views/Cleaning')));
const CleaningDetail = Loadable(lazy(() => import('./views/Cleaning/Detail')));

// Error Page
const ErrorPage = Loadable(lazy(() => import('./views/Error/ErrorPage')));

const App = (props) => {
    const { history } = props;
    return (
        <>
            <ConnectedRouter history={history}>
                <Switch>
                    {/* Login Router*/}
                    <Route exact path={['/admin']}>
                        {/* <Redirect to='/admin' /> */}
                        <Switch>
                            <Route path='/admin' exact component={Login} />
                        </Switch>
                    </Route>

                    {/* Admin Dashboard Router */}
                    <Route
                        exact
                        path={[
                            '/admin/order',
                            '/admin/order/:orderId?',
                            '/admin/membership',
                            '/admin/membership/:type/:membershipId?',
                            '/admin/account',
                            '/admin/account/:accountId?',
                            '/admin/collected',
                            '/admin/collected/:collectId?',
                            '/admin/item',
                            '/admin/item/:itemId?',
                            '/admin/box',
                            '/admin/box/:boxId?',
                            '/admin/request',
                            '/admin/request/:requestId?',
                            '/admin/extendBox',
                            '/admin/invoice',
                            '/admin/notification',
                            '/admin/notification/:mode?/:notificationId?',
                            '/admin/hanger',
                            '/admin/hanger/:hangerId?',
                            '/admin/shoe-repair',
                            '/admin/shoe-repair/:shoeRepairId?',
                            '/admin/notification/:notificationId?',
                            '/admin/cleaning',
                            '/admin/cleaning/:cleaningId?',
                            '/admin/data-master',
                        ]}
                    >
                        <MainLayout>
                            <Switch>
                                <Route
                                    path='/admin/order'
                                    exact
                                    component={OrderManagement}
                                />
                                <Route
                                    path='/admin/order/:orderId?'
                                    exact
                                    component={OrderDetail}
                                />
                                <Route
                                    path='/admin/membership'
                                    exact
                                    component={MembershipManagement}
                                />
                                <Route
                                    path='/admin/membership/:type/:membershipId?'
                                    exact
                                    component={MembershipDetail}
                                />
                                <Route
                                    path='/admin/account'
                                    exact
                                    component={AccountManagement}
                                />
                                <Route
                                    path='/admin/account/:accountId?'
                                    exact
                                    component={AccountDetail}
                                />
                                <Route
                                    path='/admin/collected'
                                    exact
                                    component={CollectManagement}
                                />
                                <Route
                                    path='/admin/collected/:collectId?'
                                    exact
                                    component={DetailCollect}
                                />
                                <Route
                                    path='/admin/item'
                                    exact
                                    component={ItemManagement}
                                />
                                <Route
                                    path='/admin/item/:itemId?'
                                    exact
                                    component={ItemDetail}
                                />
                                <Route
                                    path='/admin/box'
                                    exact
                                    component={BoxManagement}
                                />
                                <Route
                                    path='/admin/box/:boxId?'
                                    exact
                                    component={DetailBoxManagement}
                                />
                                <Route
                                    path='/admin/request'
                                    exact
                                    component={RequestManagement}
                                />
                                <Route
                                    path='/admin/request/:requestId?'
                                    exact
                                    component={RequestDetail}
                                />
                                <Route
                                    path='/admin/extendBox'
                                    exact
                                    component={ExtendBox}
                                />
                                <Route
                                    path='/admin/notification'
                                    exact
                                    component={Notification}
                                />
                                <Route
                                    path='/admin/notification/:notificationId?'
                                    exact
                                    component={DetailNotification}
                                />
                                <Route
                                    path='/admin/invoice'
                                    exact
                                    component={InvoiceManagement}
                                />
                                <Route
                                    path='/admin/cleaning'
                                    exact
                                    component={Cleaning}
                                />
                                <Route
                                    path='/admin/cleaning/:cleaningId?'
                                    exact
                                    component={CleaningDetail}
                                />
                                <Route
                                    path='/admin/data-master'
                                    exact
                                    component={DataMaster}
                                />
                                <Route
                                    path='/admin/hanger'
                                    exact
                                    component={Hanger}
                                />
                                <Route
                                    path='/admin/hanger/:hangerId?'
                                    exact
                                    component={HangerDetail}
                                />
                                <Route
                                    path='/admin/shoe-repair'
                                    exact
                                    component={ShoeRepair}
                                />
                                <Route
                                    path='/admin/shoe-repair/:shoeRepairId?'
                                    exact
                                    component={ShoeRepairDetail}
                                />
                            </Switch>
                        </MainLayout>
                    </Route>

                    {/* WebView Route */}
                    <Route
                        exact
                        path={[
                            '/user/reset-password',
                            '/user/confirm-reset-password',
                        ]}
                    >
                        <MobileView>
                            <Switch>
                                <Route
                                    path='/user/reset-password'
                                    exact
                                    component={ResetPassword}
                                />
                                <Route
                                    path='/user/confirm-reset-password'
                                    exact
                                    component={ConfirmResetPassword}
                                />
                            </Switch>
                        </MobileView>
                    </Route>

                    {/* Error Page Route */}
                    <Route component={ErrorPage} />
                </Switch>
            </ConnectedRouter>
        </>
    );
};

App.propTypes = {
    history: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired,
};

export default App;
