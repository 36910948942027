import { call, takeLatest, put, all, select } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Invoice';
import { waitAuthorizedCheck } from './Auth';
import { push } from 'connected-react-router';
import { PERMISSION } from '../constants/Permission';
import _ from 'lodash';
import { differenceArray } from '../helpers/DifferenceArray';

function* getListInvoice(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/invoice/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'id,desc'}`;
    if (
        !credential.data.permissions.includes(PERMISSION.LIST_INVOICE) &&
        !credential.data.permissions.includes(PERMISSION.SUPER)
    ) {
        yield put(push(`/admin/membership/update/${credential.login}`));
    }

    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...action.payload?.body },
            token: credential.data.access_token,
        });
        yield put(Actions.ListInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.ListInvoiceFailed(error));
    }
}

function* detailInvoice(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/invoice/${action.payload.invoiceId}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.DetailInvoiceClear(error));
    }
}

function* invoicePeriod() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: '/api/admin/invoice/periods',
            token: credential.data.access_token,
        });
        yield put(Actions.InvoicePeriodSuccess(response));
    } catch (error) {
        yield put(Actions.InvoicePeriodFailed(error));
    }
}

function* createInvoice() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/invoice`,
            token: credential.data.access_token,
        });
        yield put(Actions.CreateInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.CreateInvoiceFailed(error));
    }
}

function* payInvoice(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/invoice/pay?time=${encodeURIComponent(
                action.payload.time
            )}`,
            token: credential.data.access_token,
        });
        yield put(Actions.PayInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.PayInvoiceFailed(error));
    }
}

function* unconfirmedCount(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/invoice/unconfirmed/count?time=${encodeURIComponent(
                action.payload
            )}`,
            token: credential.data.access_token,
        });
        yield put(Actions.UnconfirmedCountSuccess(response));
    } catch (error) {
        yield put(Actions.UnconfirmedCountFailed(error));
    }
}

function* confirmInvoice(action) {
    const credential = yield call(waitAuthorizedCheck);
    const confirmData = yield select(
        (state) => state.invoice.confirm.confirmData
    );
    const items = yield select((state) => state.invoice?.detail?.data?.items);

    let tempBody = [];
    if (
        !_.isUndefined(confirmData.subscriptions) &&
        !_.isEmpty(items.subscriptions)
    ) {
        confirmData.subscriptions.forEach((item, index) => {
            const diff = differenceArray(
                item?.detail,
                items?.subscriptions?.[index]?.detail,
                'amount',
                'item_id'
            );
            tempBody = [...tempBody, ...diff];
        });
    }
    if (
        !_.isUndefined(confirmData.return_box_items) &&
        !_.isEmpty(items.return_box_items)
    ) {
        items.return_box_items.forEach((item, index) => {
            if (+item.amount != confirmData.return_box_items[index]?.amount) {
                tempBody = [
                    ...tempBody,
                    {
                        item_id: +confirmData.return_box_items[index]?.item_id,
                        amount: +confirmData.return_box_items[index]?.amount,
                    },
                ];
            }
        });
    }
    if (
        !_.isUndefined(confirmData.return_boxes) &&
        !_.isEmpty(items.return_boxes)
    ) {
        items.return_boxes.forEach((item, index) => {
            if (+item.amount != confirmData.return_boxes[index]?.amount) {
                tempBody = [
                    ...tempBody,
                    {
                        item_id: +confirmData.return_boxes[index]?.item_id,
                        amount: +confirmData.return_boxes[index]?.amount,
                    },
                ];
            }
        });
    }
    if (
        !_.isUndefined(confirmData.received_late_boxes) &&
        !_.isEmpty(items.received_late_boxes)
    ) {
        items.received_late_boxes.forEach((item, index) => {
            if (
                +item.amount != confirmData.received_late_boxes[index]?.amount
            ) {
                tempBody = [
                    ...tempBody,
                    {
                        item_id:
                            +confirmData.received_late_boxes[index]?.item_id,
                        amount: +confirmData.received_late_boxes[index]?.amount,
                    },
                ];
            }
        });
    }

    if (!_.isUndefined(confirmData.other)) {
        confirmData.other.forEach((item, index) => {
            if (_.isUndefined(item.item_id)) {
                tempBody = [
                    ...tempBody,
                    {
                        note: confirmData.other[index]?.note,
                        amount: +confirmData.other[index]?.amount,
                    },
                ];
            } else {
                tempBody = [...tempBody, item];
            }
        });
    }
    if (!_.isUndefined(confirmData.services) && !_.isEmpty(items.services)) {
        confirmData.services.forEach((item, index) => {
            const diff = differenceArray(
                item?.detail,
                items?.services?.[index]?.detail,
                'amount',
                'item_id'
            );
            tempBody = [...tempBody, ...diff];
        });
    }

    try {
        const response = yield call(API.postArray, {
            endpoint: `/api/admin/invoice/${action.payload.invoiceId}/confirm`,
            body: tempBody,
            token: credential.data.access_token,
        });
        yield put(Actions.ConfirmInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.ConfirmInvoiceFailed(error));
    }
}

function* paidInvoice(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.put, {
            endpoint: `/api/admin/invoice/${action.payload.invoiceId}/paid`,
            token: credential.data.access_token,
        });
        yield put(Actions.PaidInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.PaidInvoiceFailed(error));
    }
}

function* updateInvoice() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.put, {
            endpoint: '/api/admin/invoice/status',
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateInvoiceSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateInvoiceFailed(error));
    }
}

export default function* Invoice() {
    yield all([
        takeLatest('LIST_INVOICE_REQUEST', getListInvoice),
        takeLatest('DETAIL_INVOICE_REQUEST', detailInvoice),
        takeLatest('LIST_INVOICE_SUCCESS', invoicePeriod),
        takeLatest('CREATE_INVOICE_REQUEST', createInvoice),
        takeLatest('PAY_INVOICE_REQUEST', payInvoice),
        takeLatest('UNCONFIRMED_COUNT_REQUEST', unconfirmedCount),
        takeLatest('CONFIRM_INVOICE_REQUEST', confirmInvoice),
        takeLatest('PAID_INVOICE_REQUEST', paidInvoice),
        takeLatest('UPDATE_INVOICE_REQUEST', updateInvoice),
    ]);
}
