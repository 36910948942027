import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Notification';
import { waitAuthorizedCheck } from './Auth';

function* getListNotification(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/notification/search?size=30&page=${
                action.payload?.get?.page ?? 0
            }&sort=${action.payload?.get?.sort ?? 'id,desc'}`,
            body: { ...action.payload?.post },
            token: credential.data.access_token,
        });
        yield put(Actions.ListNotificationSuccess(response));
    } catch (error) {
        yield put(Actions.ListNotificationFailed(error));
    }
}

function* getDetailNotification(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/notification/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailNotificationSuccess(response));
    } catch (error) {
        yield put(Actions.DetailNotificationFailed(error));
    }
}

function* createNotification(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: '/api/admin/notification',
            body: action.payload,
            token: credential.data.access_token,
        });
        yield put(Actions.CreateNotificationSuccess(response));
    } catch (error) {
        yield put(Actions.CreateNotificationFailed(error));
    }
}

function* updateNotification(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.put, {
            endpoint: `/api/admin/notification/${action.payload.notificationId}`,
            body: action.payload.data,
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateNotificationSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateNotificationFailed(error));
    }
}

function* endNotification(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/notification/${action.payload.notificationId}/end`,
            token: credential.data.access_token,
        });
        yield put(Actions.EndNotificationSuccess(response));
    } catch (error) {
        yield put(Actions.EndNotificationFailed(error));
    }
}

export default function* Box() {
    yield all([
        takeLatest('LIST_NOTIFICATION_REQUEST', getListNotification),
        takeLatest('DETAIL_NOTIFICATION_REQUEST', getDetailNotification),
        takeLatest('CREATE_NOTIFICATION_REQUEST', createNotification),
        takeLatest('UPDATE_NOTIFICATION_REQUEST', updateNotification),
        takeLatest('END_NOTIFICATION_REQUEST', endNotification),
    ]);
}
