import { call, takeLatest, put, all, select } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Request';
import { createReturnPriceSelector } from '../selectors/Request';
import { waitAuthorizedCheck } from './Auth';
import format from 'date-fns/format';
function* getListRequestReturn(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/return/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'orderDate,desc'}`;
    let bodyRequest = { status: 'NEW' };
    if (action.payload?.body)
        bodyRequest = { ...bodyRequest, ...action.payload.body };

    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListRequestReturnSuccess(response));
    } catch (error) {
        yield put(Actions.ListRequestReturnFailed(error));
    }
}

function* getDetailRequestReturn(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/return/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailRequestReturnSuccess(response));
    } catch (error) {
        yield put(Actions.DetailRequestReturnFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/order/return/export/all?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            filename: `取り出し依頼管理_${format(new Date(), 'yyyyMMdd')}.csv`,
        });
        yield put(Actions.ExportCsvRequestReturnSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvRequestReturnFailed(error));
    }
}

function* getReturnPrice(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: `/api/data/plan/return/price?time=${encodeURIComponent(
                action.payload?.order_info?.created_date
            )}`,
            token: credential.data.access_token,
        });
        yield put(Actions.ReturnPriceSuccess(response));
    } catch (error) {
        yield put(Actions.ReturnPriceFailed(error));
    }
}

function* confirmRequestReturn(action) {
    const credential = yield call(waitAuthorizedCheck);
    const returnPrice = yield select(createReturnPriceSelector());
    let tempReturnBoxes = [];
    if (action.payload.return_boxes) {
        tempReturnBoxes = action.payload?.return_boxes.map((box) => {
            // if (
            //     _.isUndefined(box.custom_price) ||
            //     _.isUndefined(box.custom_price.plan_box_id)
            // ) {
            //     return {
            //         plan_box_id: returnPrice.filter_by_name[box.name],
            //         custom_price: +box.price,
            //         quantity: 1,
            //     };
            // }
            if (
                returnPrice.filter_price[box.plan_box_id] !== +box.custom_price
            ) {
                return {
                    plan_box_id: box.plan_box_id,
                    custom_price: +box.custom_price,
                    quantity: 1,
                };
            }
            return {
                plan_box_id: box.plan_box_id,
                quantity: 1,
            };
        });
    }

    try {
        const response = yield call(API.postArray, {
            endpoint: `/api/admin/order/return/${action.payload.order_id}/confirm`,
            body: [...tempReturnBoxes],
            token: credential.data.access_token,
        });
        yield put(Actions.ConfirmRequestReturnSuccess(response));
    } catch (error) {
        yield put(Actions.ConfirmRequestReturnFailed(error));
    }
}

function* completeRequestReturn(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/return/${action.payload.order_id}/complete`,
            body: { code: action.payload.code },
            token: credential.data.access_token,
        });
        yield put(Actions.CompleteRequestReturnSuccess(response));
    } catch (error) {
        yield put(Actions.CompleteRequestReturnFailed(error));
    }
}

function* getHangingItemReturn(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/return/${action.payload}/hanging`,
            token: credential.data.access_token,
        });
        yield put(Actions.HangingItemReturnSuccess(response));
    } catch (error) {
        yield put(Actions.HangingItemReturnFailed(error));
    }
}

export default function* Order() {
    yield all([
        takeLatest('LIST_REQUEST_RETURN_REQUEST', getListRequestReturn),
        takeLatest('DETAIL_REQUEST_RETURN_REQUEST', getDetailRequestReturn),
        takeLatest('EXPORT_CSV_REQUEST_RETURN_REQUEST', exportCsv),
        takeLatest('DETAIL_REQUEST_RETURN_SUCCESS', getReturnPrice),
        takeLatest('CONFIRM_REQUEST_RETURN_REQUEST', confirmRequestReturn),
        takeLatest('COMPLETE_REQUEST_RETURN_REQUEST', completeRequestReturn),
        takeLatest('HANGING_ITEM_RETURN_REQUEST', getHangingItemReturn),
    ]);
}
