export default function componentStyleOverrides(theme) {
    return {
        MuiButton: {
            root: {
                fontWeight: 500,
                textTransform: 'capitalize',
                borderRadius: '4px',
            },
        },
        MuiFormControl: {
            root: { flex: '1 1 0%' },
        },
        MuiPaper: {
            root: {
                backgroundImage: 'none',
            },
            rounded: {
                borderRadius: `${theme.customization.borderRadius}px`,
            },
        },
        MuiCardHeader: {
            root: {
                color: theme.colors.textDark,
                padding: '24px',
            },
            title: {
                fontSize: '1.125rem',
            },
        },
        MuiCardContent: {
            root: {
                padding: '24px',
            },
        },
        MuiCardActions: {
            root: {
                padding: '24px',
            },
        },
        MuiListItem: {
            root: {
                color: theme.darkTextPrimary,
                paddingTop: '10px',
                paddingBottom: '10px',
                '&.Mui-selected': {
                    color: theme.menuSelected,
                    backgroundColor: theme.menuSelectedBack,
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                    },
                    '& .MuiListItemIcon-root': {
                        color: theme.menuSelected,
                    },
                },
                '&:hover': {
                    borderRadius: 8,
                    backgroundColor: `${theme.menuSelectedBack} !important`,
                    color: theme.menuSelected,
                    cursor: 'pointer',
                    '& .MuiListItemIcon-root': {
                        color: theme.menuSelected,
                    },
                },
            },
        },
        MuiListItemIcon: {
            root: {
                color: theme.darkTextPrimary,
                minWidth: '36px',
            },
        },
        MuiListItemText: {
            primary: {
                color: theme.textDark,
            },
        },
        MuiInputBase: {
            input: {
                color: theme.textDark,
                '&::placeholder': {
                    color: theme.darkTextSecondary,
                    fontSize: '0.875rem',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                background: theme.colors.grey50,
                borderRadius: `${theme.customization.borderRadius}px`,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors.grey400,
                },
                '&:hover $notchedOutline': {
                    borderColor: theme.colors.primaryLight,
                },
                '&.MuiInputBase-multiline': {
                    padding: 1,
                },
            },
            input: {
                fontWeight: 500,
                background: theme.colors.grey50,
                padding: '15.5px 14px',
                borderRadius: `${theme.customization.borderRadius}px`,
                '&.MuiInputBase-inputSizeSmall': {
                    padding: '10px 14px',
                    '&.MuiInputBase-inputAdornedStart': {
                        paddingLeft: 0,
                    },
                },
            },
            inputAdornedStart: {
                paddingLeft: 4,
            },
            notchedOutline: {
                borderRadius: `${theme.customization.borderRadius}px`,
            },
        },
        MuiSlider: {
            root: {
                '&.Mui-disabled': {
                    color: theme.colors.grey300,
                },
            },
            mark: {
                backgroundColor: theme.paper,
                width: '4px',
            },
            valueLabel: {
                color: theme.colors.primaryLight,
            },
        },
        MuiDivider: {
            root: {
                borderColor: theme.divider,
                opacity: 1,
            },
        },
        MuiAvatar: {
            root: {
                color: theme.colors.primaryDark,
                background: theme.colors.primary200,
            },
        },
        MuiChip: {
            root: {
                '&.MuiChip-deletable .MuiChip-deleteIcon': {
                    color: 'inherit',
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                color: theme.paper,
                background: theme.colors.grey700,
            },
        },
        MuiSvgIcon: {
            colorDisabled: {
                color: '#9e9e9e',
            },
        },
        MuiIconButton: {
            colorSecondary: {
                color: theme.colors.errorMain,
            },
        },
    };
}
