import { createAction } from 'redux-actions';

export const GetListGroupRequest = createAction('GET_LIST_GROUP_REQUEST');
export const GetListGroupSuccess = createAction('GET_LIST_GROUP_SUCCESS');
export const GetListGroupFailed = createAction('GET_LIST_GROUP_FAILED');
export const GetListGroupClear = createAction('GET_LIST_GROUP_CLEAR');

export const GetListCategoryRequest = createAction('GET_LIST_CATEGORY_REQUEST');
export const GetListCategorySuccess = createAction('GET_LIST_CATEGORY_SUCCESS');
export const GetListCategoryFailed = createAction('GET_LIST_CATEGORY_FAILED');
export const GetListCategoryClear = createAction('GET_LIST_CATEGORY_CLEAR');