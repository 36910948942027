import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Item';

const listItemReducer = handleActions(
    {
        [Actions.ListItemRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListItemSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListItemFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListItemClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailItemReducer = handleActions(
    {
        [Actions.DetailItemRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailItemSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailItemFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailItemClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateItemReducer = handleActions(
    {
        [Actions.UpdateItemRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateItemSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateItemFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateItemClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const deleteItemReducer = handleActions(
    {
        [Actions.DeleteItemRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DeleteItemSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DeleteItemFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DeleteItemClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const exportCsvReducer = handleActions(
    {
        [Actions.ExportCsvItemRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ExportCsvItemSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ExportCsvItemFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ExportCsvItemClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const countItemReducer = handleActions(
    {
        [Actions.CountItemBoxRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CountItemBoxSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CountItemBoxFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CountItemBoxClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listItemReducer,
    detail: detailItemReducer,
    update: updateItemReducer,
    delete: deleteItemReducer,
    exportcsv: exportCsvReducer,
    count: countItemReducer,
});
