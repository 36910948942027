import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Chip,
    Paper,
    Popper,
    Typography,
    ClickAwayListener,
    Grow,
    List,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

// project imports
import MainCard from '../../components/Card/MainCard';
import { LoginClear } from '../../actions/Auth';
import { PERMISSION } from '../../constants/Permission';
import { MENU_OPEN } from '../../actions/customizationAction';

// assets
import { IconSettings } from '@tabler/icons';

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
        },
    },
    headerAvatar: {
        cursor: 'pointer',
        width: '34px',
        height: '34px',
        fontSize: '1.2rem',
        margin: '8px 0 8px 8px !important',
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: '#e3f2fd',
        backgroundColor: '#e3f2fd',
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: '#2196f3',
            background: '#2196f3!important',
            color: '#e3f2fd',
            '& svg': {
                stroke: '#e3f2fd',
            },
        },
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px',
    },
    listItem: {
        marginTop: '5px',
    },
    cardContent: {
        padding: '16px !important',
    },
    card: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px',
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px',
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500],
    },
    flex: {
        display: 'flex',
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400,
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden',
    },
    badgeWarning: {
        backgroundColor: theme.palette.warning.dark,
        color: '#fff',
    },
    titleSetting: {
        padding: '12px !important',
        fontSize: 16,
    },
}));

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.credential.login);
    const permissionUser = useSelector(
        (state) => state.auth.credential?.data?.permissions
    );

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleLogout = () => {
        dispatch(LoginClear());
        history.push('/admin');
    };

    const handleRedirectApp = (link) => {
        setOpen(false);
        dispatch({ type: MENU_OPEN, id: '' }); // Disable Selected Menu List
        history.push(link);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                icon={
                    <div ref={anchorRef} className={classes.titleSetting}>
                        {userId}
                    </div>
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size='1.5rem'
                        color={theme.palette.primary.main}
                    />
                }
                variant='outlined'
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                color='primary'
            />
            <Popper
                placement='bottom-end'
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                popperOptions={{
                    modifiers: {
                        offset: { enabled: true, offset: '0, 14' },
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard cardSetting>
                                    <List
                                        component='nav'
                                        className={classes.navContainer}
                                    >
                                        <ListItemButton
                                            className={classes.listItem}
                                            onClick={() =>
                                                handleRedirectApp(
                                                    `/admin/membership/update/${userId}?redirect_from=setting`
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant='body2'>
                                                        設定
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>

                                        {permissionUser.includes(
                                            PERMISSION.SUPER
                                        ) && (
                                            <ListItemButton
                                                className={classes.listItem}
                                                onClick={() =>
                                                    handleRedirectApp(
                                                        '/admin/membership'
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <PeopleIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body2'>
                                                            ユーザー管理
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        )}

                                        <ListItemButton
                                            className={classes.listItem}
                                            onClick={handleLogout}
                                        >
                                            <ListItemIcon>
                                                <ExitToAppIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant='body2'>
                                                        ログアウト
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
