export const PERMISSION = {
    SUPER:"SUPER",
    INQUIRY_EMAIL: "INQUIRY_EMAIL",
    MASTER_DATA:"MASTER_DATA",
    LIST_MEMBERSHIP:"LIST_MEMBERSHIP",
    DETAIL_MEMBERSHIP:"DETAIL_MEMBERSHIP",
    DETAIL_MEMBERSHIP_BOX:"DETAIL_MEMBERSHIP_BOX",
    UPDATE_DETAIL_MEMBERSHIP: "UPDATE_DETAIL_MEMBERSHIP",
    REMOVE_MEMBERSHIP: "REMOVE_MEMBERSHIP",
    LIST_ADMIN: "LIST_ADMIN",
    SELF_DETAIL_ADMIN: "SELF_DETAIL_ADMIN",
    OTHER_DETAIL_ADMIN: "OTHER_DETAIL_ADMIN",
    CREATE_ADMIN: "CREATE_ADMIN",
    RESET_PASSWORD_ADMIN: "RESET_PASSWORD_ADMIN",
    REMOVE_ADMIN: "REMOVE_ADMIN",
    SELF_UPDATE_ADMIN: "SELF_UPDATE_ADMIN",
    OTHER_UPDATE_ADMIN: "OTHER_UPDATE_ADMIN",
    LIST_GROUP: "LIST_GROUP",
    LIST_BOX_ORDER: "LIST_BOX_ORDER",
    DETAIL_BOX_ORDER: "DETAIL_BOX_ORDER",
    PROCESS_BOX_ORDER: "PROCESS_BOX_ORDER",
    LIST_COLLECTED_ORDER: "LIST_COLLECTED_ORDER",
    DETAIL_COLLECTED_ORDER: "DETAIL_COLLECTED_ORDER",
    PROCESS_COLLECTED_ORDER: "PROCESS_COLLECTED_ORDER",
    LIST_RETURN_ORDER: "LIST_RETURN_ORDER",
    DETAIL_RETURN_ORDER: "DETAIL_RETURN_ORDER",
    PROCESS_RETURN_ORDER: "PROCESS_RETURN_ORDER",
    RECEIVE_BOX: "RECEIVE_BOX",
    LIST_BOX: "LIST_BOX",
    DETAIL_BOX: "DETAIL_BOX",
    PROCESS_BOX: "PROCESS_BOX",
    ADD_BOX_ITEM: "ADD_BOX_ITEM",
    REMOVE_BOX_ITEM: "REMOVE_BOX_ITEM",
    LIST_BOX_ITEM: "LIST_BOX_ITEM",
    DETAIL_BOX_ITEM: "DETAIL_BOX_ITEM",
    PROCESS_BOX_ITEM: "PROCESS_BOX_ITEM",
    LIST_INVOICE: "LIST_INVOICE",
    DETAIL_INVOICE: "DETAIL_INVOICE",
    CALCULATE_INVOICE: "CALCULATE_INVOICE",
    CONFIRM_INVOICE: "CONFIRM_INVOICE",
    PAY_INVOICE: "PAY_INVOICE",
    CREATE_INVOICE: "CREATE_INVOICE",
    LIST_BROADCAST_NOTIFICATION: "LIST_BROADCAST_NOTIFICATION",
    DETAIL_BROADCAST_NOTIFICATION: "DETAIL_BROADCAST_NOTIFICATION",
    CREATE_BROADCAST_NOTIFICATION: "CREATE_BROADCAST_NOTIFICATION",
    LIST_HANGER_ORDER: "LIST_HANGER_ORDER",
    DETAIL_HANGER_ORDER: "DETAIL_HANGER_ORDER",
    PROCESS_HANGER_ORDER: "PROCESS_HANGER_ORDER",
    LIST_SERVICE_ORDER: "LIST_SERVICE_ORDER",
    PROCESS_SERVICE_ORDER: "PROCESS_SERVICE_ORDER"


};

Object.freeze(PERMISSION);