import { createTheme } from '@material-ui/core/styles';
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';

const themeOption = {
    colors: {
        paper: '#ffffff',
        primaryLight: '#e3f2fd',
        primaryMain: '#2196f3',
        primaryDark: '#1e88e5',
        primary200: '#90caf9',
        primary800: '#1565c0',
        secondaryLight: '#ede7f6',
        secondaryMain: '#673ab7',
        secondaryDark: '#5e35b1',
        secondary200: '#b39ddb',
        secondary800: '#4527a0',
        successLight: '#b9f6ca',
        success200: '#69f0ae',
        successMain: '#00e676',
        successDark: '#00c853',
        errorLight: '#ef9a9a',
        errorMain: '#f44336',
        errorDark: '#c62828',
        orangeLight: '#fbe9e7',
        orangeMain: '#ffab91',
        orangeDark: '#d84315',
        warningLight: '#fff8e1',
        warningMain: '#ffe57f',
        warningDark: '#ffc107',
        grey50: '#fafafa',
        grey100: '#f5f5f5',
        grey200: '#eeeeee',
        grey300: '#e0e0e0',
        grey500: '#9e9e9e',
        grey600: '#757575',
        grey700: '#616161',
        grey900: '#212121',
    },
    heading: '#212121',
    paper: '#ffffff',
    backgroundDefault: '#ffffff',
    background: '#e3f2fd',
    darkTextPrimary: '#616161',
    darkTextSecondary: '#9e9e9e',
    textDark: '#212121',
    menuSelected: '#1e88e5',
    menuSelectedBack: '#e3f2fd',
    divider: '#eeeeee',
    customization: {
        borderRadius: 12,
    },
};

const theme = createTheme({
    typography: {
        fontFamily: [
            /* font Hiragino sans */
            // 'Hiragino Sans',
            // 'ヒラギノ角ゴシック',
            // 'Meiryo',
            // 'メイリオ',
            // 'YuGothic',
            // 'Yu Gothic',
            // 'ＭＳ Ｐゴシック',
            // 'MS PGothic',
            /* font Hiragino sans */
            'Yu Gothic UI',
            'メイリオ',
            'Meiryo',
            'ヒラギノ角ゴ Pro W3',
            'Hiragino Kaku Gothic Pro',
            'Osaka',
            'MS PGothic',
            'ＭＳ Ｐゴシック',
            'sans-serif',
        ].join(','),
        fontWeight: 400,
        lineHeight: '1.5',
        h6: {
            fontWeight: 700,
            fontSize: 14,
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
        h1: {
            fontSize: '2.125rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em',
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
        },
        subtitle1: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#212121',
        },
    },
    palette: themePalette(),
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1200,
            xl: 1920,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                'html, body, #root': {
                    height: '100%',
                },
                '#root': {
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                },
            },
        },
        ...componentStyleOverrides(themeOption),
    },
});

export default theme;
