import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Hanger';
import { waitAuthorizedCheck } from './Auth';
import format from 'date-fns/format';

function* getListHanger(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/hanger/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'id,desc'}`;
    let bodyRequest = { status: 'NEW' };
    if (action.payload?.body)
        bodyRequest = { ...bodyRequest, ...action.payload.body };
    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListHangerSuccess(response));
    } catch (error) {
        yield put(Actions.ListHangerFailed(error));
    }
}

function* getDetailHanger(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/hanger/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailHangerSuccess(response));
    } catch (error) {
        yield put(Actions.DetailHangerFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/order/hanger/complete?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            filename: `ハンガー保管依頼管理_${format(
                new Date(),
                'yyyyMMdd'
            )}.csv`,
        });
        yield put(Actions.ExportCsvHangerSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvHangerFailed(error));
    }
}

function* registerHanger(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/hanger/${action.payload}/process`,
            token: credential.data.access_token,
        });
        yield put(Actions.RegisterHangerSuccess(response));
    } catch (error) {
        yield put(Actions.RegisterHangerFailed(error));
    }
}

function* cancelHanger(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/hanger/${action.payload}/cancel`,
            token: credential.data.access_token,
        });
        yield put(Actions.CancelHangerSuccess(response));
    } catch (error) {
        yield put(Actions.CancelHangerFailed(error));
    }
}

function* countHangerProcessing() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: '/api/admin/order/hanger/count/processing',
            token: credential.data.access_token,
        });
        yield put(Actions.CountHangerProcessingSuccess(response));
    } catch (error) {
        yield put(Actions.CountHangerProcessingClear(error));
    }
}

export default function* Hanger() {
    yield all([
        takeLatest('LIST_HANGER_REQUEST', getListHanger),
        takeLatest('DETAIL_HANGER_REQUEST', getDetailHanger),
        takeLatest('REGISTER_HANGER_REQUEST', registerHanger),
        takeLatest('CANCEL_HANGER_REQUEST', cancelHanger),
        takeLatest('EXPORT_CSV_HANGER_REQUEST', exportCsv),
        takeLatest('COUNT_HANGER_PROCESSING_REQUEST', countHangerProcessing),
    ]);
}
