import { createAction } from 'redux-actions';

export const ListRequestReturnRequest         = createAction('LIST_REQUEST_RETURN_REQUEST');
export const ListRequestReturnSuccess         = createAction('LIST_REQUEST_RETURN_SUCCESS');
export const ListRequestReturnFailed          = createAction('LIST_REQUEST_RETURN_FAILED');
export const ListRequestReturnClear           = createAction('LIST_REQUEST_RETURN_CLEAR');

export const DetailRequestReturnRequest       = createAction('DETAIL_REQUEST_RETURN_REQUEST');
export const DetailRequestReturnSuccess       = createAction('DETAIL_REQUEST_RETURN_SUCCESS');
export const DetailRequestReturnFailed        = createAction('DETAIL_REQUEST_RETURN_FAILED');
export const DetailRequestReturnClear         = createAction('DETAIL_REQUEST_RETURN_CLEAR');

export const ExportCsvRequestReturnRequest    = createAction('EXPORT_CSV_REQUEST_RETURN_REQUEST');
export const ExportCsvRequestReturnSuccess    = createAction('EXPORT_CSV_REQUEST_RETURN_SUCCESS');
export const ExportCsvRequestReturnFailed     = createAction('EXPORT_CSV_REQUEST_RETURN_FAILED');
export const ExportCsvRequestReturnClear      = createAction('EXPORT_CSV_REQUEST_RETURN_CLEAR');

export const ConfirmRequestReturnRequest      = createAction('CONFIRM_REQUEST_RETURN_REQUEST');
export const ConfirmRequestReturnSuccess      = createAction('CONFIRM_REQUEST_RETURN_SUCCESS');
export const ConfirmRequestReturnFailed       = createAction('CONFIRM_REQUEST_RETURN_FAILED');
export const ConfirmRequestReturnClear        = createAction('CONFIRM_REQUEST_RETURN_CLEAR');

export const CompleteRequestReturnRequest     = createAction('COMPLETE_REQUEST_RETURN_REQUEST');
export const CompleteRequestReturnSuccess     = createAction('COMPLETE_REQUEST_RETURN_SUCCESS');
export const CompleteRequestReturnFailed      = createAction('COMPLETE_REQUEST_RETURN_FAILED');
export const CompleteRequestReturnClear       = createAction('COMPLETE_REQUEST_RETURN_CLEAR');

export const ReturnPriceRequest               = createAction('RETURN_PRICE_REQUEST');
export const ReturnPriceSuccess               = createAction('RETURN_PRICE_SUCCESS');
export const ReturnPriceFailed                = createAction('RETURN_PRICE_FAILED');
export const ReturnPriceClear                 = createAction('RETURN_PRICE_CLEAR');

export const HangingItemReturnRequest         = createAction('HANGING_ITEM_RETURN_REQUEST');
export const HangingItemReturnSuccess         = createAction('HANGING_ITEM_RETURN_SUCCESS');
export const HangingItemReturnFailed          = createAction('HANGING_ITEM_RETURN_FAILED');
export const HangingItemReturnClear           = createAction('HANGING_ITEM_RETURN_CLEAR');