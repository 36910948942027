import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Auth';
import { addMinutes } from 'date-fns';
import { PERMISSION } from '../constants/Permission';

const AuthTokenReducer = handleActions(
    {
        [Actions.LoginRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.LoginSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isAuthorized: !!action.payload.access_token,
            isAdmin: action.payload.permissions.includes(PERMISSION.SUPER),
            data: action.payload,
            expired_time: addMinutes(new Date(), 1),
            login: action.payload.login,
            errors: {},
        }),
        [Actions.LoginFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            errors: action.payload.errors,
            isAuthorized: false,
        }),
        [Actions.LoginClear]: (state) => ({
            ...state,
            isFetching: false,
            isAuthorized: false,
            isAdmin: false,
            data: {},
            expired_time: 0,
            errors: {},
        }),
        [Actions.RefreshTokenSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isAuthorized: !!action.payload.access_token,
            isAdmin: action.payload.permissions.includes(PERMISSION.SUPER),
            data: action.payload,
            expired_time: addMinutes(new Date(), 1),
            errors: {},
        }),
    },
    {
        isFetching: false,
        isAuthorized: false,
        isAdmin: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    credential: AuthTokenReducer,
});
