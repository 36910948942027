import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// import from material ui
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Redux
// Reducers
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Sagas
import sagas from './sagas';

import { store, persistor, sagaMiddleware, history } from './store/Store';

// Theme
import theme from './themes/Theme';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import App from './App';

// style
import './assets/scss/style.scss';
// style cropperjs
import 'cropperjs/dist/cropper.css';

sagaMiddleware.run(sagas);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <NavigationScroll>
                        <CssBaseline />
                        <App history={history} />
                    </NavigationScroll>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
