import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Cleaning';
import { waitAuthorizedCheck } from './Auth';

function* getListCleaning(action) {
    const credential = yield call(waitAuthorizedCheck);
    let baseEndPoint = `/api/admin/order/service/search?size=30&page=${
        action.payload?.page ?? 0
    }&sort=${action.payload?.sort ?? 'id,desc'}`;
    let bodyRequest = { status: 'NEW', service_ids: [8, 9] };
    if (action.payload?.body)
        bodyRequest = { ...bodyRequest, ...action.payload.body };
    try {
        const response = yield call(API.post, {
            endpoint: baseEndPoint,
            body: { ...bodyRequest },
            token: credential.data.access_token,
        });
        yield put(Actions.ListCleaningSuccess(response));
    } catch (error) {
        yield put(Actions.ListCleaningFailed(error));
    }
}

function* getDetailCleaning(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/service/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailCleaningSuccess(response));
    } catch (error) {
        yield put(Actions.DetailCleaningFailed(error));
    }
}

function* registerCleaning(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload.cleaning_id}/process`,
            body: { ...action.payload.body },
            token: credential.data.access_token,
        });
        yield put(Actions.RegisterCleaningSuccess(response));
    } catch (error) {
        yield put(Actions.RegisterCleaningFailed(error));
    }
}

function* cancelCleaning(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload}/cancel`,
            token: credential.data.access_token,
        });
        yield put(Actions.CancelCleaningSuccess(response));
    } catch (error) {
        yield put(Actions.CancelCleaningFailed(error));
    }
}

function* completeCleaning(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/order/service/${action.payload.cleaning_id}/complete`,
            body: { ...action.payload.body },
            token: credential.data.access_token,
        });
        yield put(Actions.CompleteCleaningSuccess(response));
    } catch (error) {
        yield put(Actions.CompleteCleaningFailed(error));
    }
}

export default function* Cleaning() {
    yield all([
        takeLatest('LIST_CLEANING_REQUEST', getListCleaning),
        takeLatest('DETAIL_CLEANING_REQUEST', getDetailCleaning),
        takeLatest('REGISTER_CLEANING_REQUEST', registerCleaning),
        takeLatest('CANCEL_CLEANING_REQUEST', cancelCleaning),
        takeLatest('COMPLETE_CLEANING_REQUEST', completeCleaning),
    ]);
}
