import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Membership';
import { waitAuthorizedCheck } from './Auth';

function* getListMembership(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.get, {
            endpoint: '/api/admin/list',
            body: { ...action.payload },
            token: credential.data.access_token,
        });
        yield put(Actions.ListMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.ListMembershipFailed(error));
    }
}

function* getDetailMembership(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/${action.payload}`,
            token: credential.data.access_token,
        });

        yield put(Actions.DetailMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.DetailMembershipFailed(error));
    }
}

function* createMembership(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: '/api/admin/',
            body: {
                ...action.payload,
                ...(action.payload.userId && {
                    email: action.payload.userId.trim(),
                }),
                ...(action.payload.email && {
                    email: action.payload.email.trim(),
                }),
                ...(action.payload.department && {
                    department: action.payload.department.trim(),
                }),
                ...(action.payload.password && {
                    password: action.payload.password.trim(),
                }),
            },
            token: credential.data.access_token,
        });

        yield put(Actions.CreateMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.CreateMembershipFailed(error));
    }
}

function* updateMembership(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.put, {
            endpoint: `/api/admin/${action.payload.membership_id}`,
            body: {
                ...action.payload.form,
                ...(action.payload.form.email && {
                    email: action.payload.form.email.trim(),
                }),
                ...(action.payload.form.department && {
                    department: action.payload.form.department.trim(),
                }),
            },
            token: credential.data.access_token,
        });

        yield put(Actions.UpdateMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateMembershipFailed(error));
    }
}

function* deleteMembership(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.delete, {
            endpoint: `/api/admin/${action.payload}`,
            token: credential.data.access_token,
        });

        yield put(Actions.DeleteMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.DeleteMembershipFailed(error));
    }
}

function* resetPasswordMembership(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.put, {
            endpoint: `/api/admin/reset-password/${action.payload}`,
            token: credential.data.access_token,
        });

        yield put(Actions.ResetPasswordMembershipSuccess(response));
    } catch (error) {
        yield put(Actions.ResetPasswordMembershipFailed(error));
    }
}

export default function* Membership() {
    yield all([
        takeLatest('LIST_MEMBERSHIP_REQUEST', getListMembership),
        takeLatest('DETAIL_MEMBERSHIP_REQUEST', getDetailMembership),
        takeLatest('CREATE_MEMBERSHIP_REQUEST', createMembership),
        takeLatest('UPDATE_MEMBERSHIP_REQUEST', updateMembership),
        takeLatest('DELETE_MEMBERSHIP_REQUEST', deleteMembership),
        takeLatest(
            'RESET_PASSWORD_MEMBERSHIP_REQUEST',
            resetPasswordMembership
        ),
    ]);
}
