import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/DataMaster';

const categoriesReducer = handleActions(
    {
        [Actions.DataCategoriesRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DataCategoriesSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DataCategoriesFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DataCategoriesClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const boxReducer = handleActions(
    {
        [Actions.DataBoxRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DataBoxSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DataBoxFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DataBoxClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const boxReturnReducer = handleActions(
    {
        [Actions.DataBoxReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DataBoxReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DataBoxReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DataBoxReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const servicePriceReducer = handleActions(
    {
        [Actions.DataServicePriceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DataServicePriceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DataServicePriceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DataServicePriceFailed]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const dialogDataCategoriesReducer = handleActions(
    {
        [Actions.DialogDataCategoriesRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DialogDataCategoriesSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DialogDataCategoriesFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DialogDataCategoriesClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateCategoriesReducer = handleActions(
    {
        [Actions.UpdateCategoriesRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateCategoriesSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateCategoriesFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateCategoriesClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const dialogDataBoxReducer = handleActions(
    {
        [Actions.DialogDataBoxRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DialogDataBoxSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DialogDataBoxFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DialogDataBoxClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateBoxReducer = handleActions(
    {
        [Actions.UpdateBoxRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateBoxSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateBoxFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateBoxClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const dialogDataBoxReturnReducer = handleActions(
    {
        [Actions.DialogDataBoxReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DialogDataBoxReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DialogDataBoxReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DialogDataBoxReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateBoxReturnReducer = handleActions(
    {
        [Actions.UpdateBoxReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateBoxReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateBoxReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateBoxReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const dialogServicePriceReducer = handleActions(
    {
        [Actions.DialogDataServicePriceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DialogDataServicePriceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: { ...state.data, ...action.payload },
            errors: {},
        }),
        [Actions.DialogDataServicePriceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DialogDataServicePriceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {
                service_hanger: [],
                service_cleaning: [],
                service_shoe_repair: [],
                service_insurance: [],
            },
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {
            service_hanger: [],
            service_cleaning: [],
            service_shoe_repair: [],
            service_insurance: [],
        },
        errors: {},
    }
);

const updateServicePriceReducer = handleActions(
    {
        [Actions.UpdateServicePriceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateServiceHangerSuccess]: (state) => ({
            ...state,
            isFetching: false,
            isUpdateServiceHangerSuccess: true,
            errors: {},
        }),
        [Actions.UpdateServiceCleaningSuccess]: (state) => ({
            ...state,
            isFetching: false,
            isUpdateServiceCleaningSuccess: true,
            errors: {},
        }),
        [Actions.UpdateServiceShoeRepairSuccess]: (state) => ({
            ...state,
            isFetching: false,
            isUpdateServiceShoeRepairSuccess: true,
            errors: {},
        }),
        [Actions.UpdateServiceInsuranceSuccess]: (state) => ({
            ...state,
            isFetching: false,
            isUpdateServiceInsuranceSuccess: true,
            errors: {},
        }),
        [Actions.UpdateServicePriceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isUpdateServiceHangerSuccess: false,
            isUpdateServiceCleaningSuccess: false,
            isUpdateServiceShoeRepairSuccess: false,
            isUpdateServiceInsuranceSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateServicePriceClear]: (state) => ({
            ...state,
            isFetching: false,
            isUpdateServiceHangerSuccess: false,
            isUpdateServiceCleaningSuccess: false,
            isUpdateServiceShoeRepairSuccess: false,
            isUpdateServiceInsuranceSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isUpdateServiceHangerSuccess: false,
        isUpdateServiceCleaningSuccess: false,
        isUpdateServiceShoeRepairSuccess: false,
        isUpdateServiceInsuranceSuccess: false,
        errors: {},
    }
);

export default combineReducers({
    categories: categoriesReducer,
    box: boxReducer,
    boxReturn: boxReturnReducer,
    servicePrice: servicePriceReducer,
    dialogCategories: dialogDataCategoriesReducer,
    updateCategories: updateCategoriesReducer,
    dialogBox: dialogDataBoxReducer,
    updateBox: updateBoxReducer,
    dialogBoxReturn: dialogDataBoxReturnReducer,
    updateBoxReturn: updateBoxReturnReducer,
    dialogServicePrice: dialogServicePriceReducer,
    updateServicePrice: updateServicePriceReducer,
});
