import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Request';

const listRequestReturnReducer = handleActions(
    {
        [Actions.ListRequestReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListRequestReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListRequestReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListRequestReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailRequestReturnReducer = handleActions(
    {
        [Actions.DetailRequestReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailRequestReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailRequestReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailRequestReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const confirmRequestReturnReducer = handleActions(
    {
        [Actions.ConfirmRequestReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ConfirmRequestReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ConfirmRequestReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ConfirmRequestReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const completeRequestReturnReducer = handleActions(
    {
        [Actions.CompleteRequestReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CompleteRequestReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CompleteRequestReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CompleteRequestReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const exportCsvReducer = handleActions(
    {
        [Actions.ExportCsvRequestReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ExportCsvRequestReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ExportCsvRequestReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ExportCsvRequestReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const returnPriceReducer = handleActions(
    {
        [Actions.ReturnPriceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ReturnPriceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ReturnPriceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ReturnPriceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const hangingItemReturnReducer = handleActions(
    {
        [Actions.HangingItemReturnRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.HangingItemReturnSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.HangingItemReturnFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.HangingItemReturnClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);
export default combineReducers({
    list: listRequestReturnReducer,
    detail: detailRequestReturnReducer,
    confirm: confirmRequestReturnReducer,
    complete: completeRequestReturnReducer,
    exportcsv: exportCsvReducer,
    price: returnPriceReducer,
    hangingitem: hangingItemReturnReducer,
});
