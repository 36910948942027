import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// third-party
import clsx from 'clsx';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    AppBar,
    CssBaseline,
    Toolbar,
    useMediaQuery,
    Typography,
} from '@material-ui/core';

// project imports
import Header from './Header';
import SideBar from './SideBar/index';
import Snackbar from '../components/Snackbar/CustomizedSnackbars';
import DialogNetworkError from '../components/DialogNetworkError/DialogNetworkError';
import { CloseSnackbar } from '../actions/Alert';
import { SET_MENU } from '../actions/customizationAction';

const drawerWidth = 260;

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: theme.palette.background.default,
    },
    appBarWidth: {
        transition: theme.transitions.create('width'),
        backgroundColor: theme.palette.background.default,
    },
    content: {
        position: 'relative',
        backgroundColor: '#e3f2fd',
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `12px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    },
    copyRight: {
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}));

const MainLayout = (props) => {
    const { children } = props;
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const isAuthorized = useSelector(
        (state) => state.auth.credential.isAuthorized
    );
    const snackbarSelector = useSelector((state) => state.alert?.snackbar);
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        if (!isAuthorized) {
            history.push('/admin');
        }
    }, []);

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
    }, [matchDownMd]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* header */}
            <AppBar
                position='fixed'
                color='inherit'
                elevation={0}
                className={
                    leftDrawerOpened ? classes.appBarWidth : classes.appBar
                }
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <SideBar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            {/* main content */}
            <main
                className={clsx([
                    classes.content,
                    {
                        [classes.contentShift]: leftDrawerOpened,
                    },
                ])}
            >
                {children}
                <div className={classes.copyRight}>
                    <Typography variant='subtitle1'>
                        Copy Right &#xA9; Saint Service. All Rights Reserved.
                    </Typography>
                </div>
            </main>

            <Snackbar
                isOpen={snackbarSelector.isOpen}
                severity={snackbarSelector.severity}
                message={snackbarSelector.message}
                onClose={() => dispatch(CloseSnackbar())}
            />
            <DialogNetworkError />
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;
