import { createAction } from 'redux-actions';

export const ListItemRequest         = createAction('LIST_ITEM_REQUEST');
export const ListItemSuccess         = createAction('LIST_ITEM_SUCCESS');
export const ListItemFailed          = createAction('LIST_ITEM_FAILED');
export const ListItemClear           = createAction('LIST_ITEM_CLEAR');

export const DetailItemRequest       = createAction('DETAIL_ITEM_REQUEST');
export const DetailItemSuccess       = createAction('DETAIL_ITEM_SUCCESS');
export const DetailItemFailed        = createAction('DETAIL_ITEM_FAILED');
export const DetailItemClear         = createAction('DETAIL_ITEM_CLEAR');

export const UpdateItemRequest       = createAction('UPDATE_ITEM_REQUEST');
export const UpdateItemSuccess       = createAction('UPDATE_ITEM_SUCCESS');
export const UpdateItemFailed        = createAction('UPDATE_ITEM_FAILED');
export const UpdateItemClear         = createAction('UPDATE_ITEM_CLEAR');

export const DeleteItemRequest       = createAction('DELETE_ITEM_REQUEST');
export const DeleteItemSuccess       = createAction('DELETE_ITEM_SUCCESS');
export const DeleteItemFailed        = createAction('DELETE_ITEM_FAILED');
export const DeleteItemClear         = createAction('DELETE_ITEM_CLEAR');

export const ExportCsvItemRequest    = createAction('EXPORT_CSV_ITEM_REQUEST');
export const ExportCsvItemSuccess    = createAction('EXPORT_CSV_ITEM_SUCCESS');
export const ExportCsvItemFailed     = createAction('EXPORT_CSV_ITEM_FAILED');
export const ExportCsvItemClear      = createAction('EXPORT_CSV_ITEM_CLEAR');

export const CountItemBoxRequest     = createAction('COUNT_ITEM_BOX_REQUEST');
export const CountItemBoxSuccess     = createAction('COUNT_ITEM_BOX_SUCCESS');
export const CountItemBoxFailed      = createAction('COUNT_ITEM_BOX_FAILED');
export const CountItemBoxClear       = createAction('COUNT_ITEM_BOX_CLEAR');
