export const MESSAGE = {
    E_MG10002_01: "メールアドレスを正しく入力してください。",
    E_MG0001_02: "IDまたはパスワードが正しくありません。再度入力してください。",
    E_MG0001_03: "この項目は必須です。",
    S_MG10002_01: "更新しました。",
    E_AP01_120_01: "読み込みに失敗しました。",
    E_MG7002_01: "削除条件を満たさない会員",
    E_MG10004_01: "既に存在しているユーザーIDです。",
    E_AP01_110_01: "すでに登録されているメールアドレスです。",
    S_MG99008_01: "CSVファイル出力に成功しました。",
    E_MG1004_UPDATE_MEMBERSHIP: {
        8003: {
            field: "email",
            msg :"すでに登録されているメールアドレスです。"
        },
        8004: {
            field: "user_id",
            msg :"既に登録されているユーザーIDです。",
        },
    },
    E_MG10003_CREATE_MEMBERSHIP: {
        8003: {
            field: "email",
            msg :"すでに登録されているメールアドレスです。"
        },
        8004: {
            field: "user_id",
            msg :"既に登録されているユーザーIDです。",
        },
    },
    E_MG3001_ID_COLLECT: "集荷依頼ID",
    E_MG3001_NUMBER_BOX: "箱数",
    E_MG3001_STATUS: "ステータス",
    E_MG3001_NAME_MEMBER: "会員名称",
    E_MG3001_SERVICE: "会員名称",
    E_MG3001_REQUEST_DATE: "依頼日時",
    E_MG3001_PICKUP_DATE: "集荷日時",
    E_MG3001_UPDATE_DATE: "更新日時",
    E_MG3001_TITLE: '集荷管理',
    E_MG3001_PRINT_CSV: "集荷依頼のCSV出力",
    E_MG3002_TITLE: "集荷詳細",
    E_MG3002_ADDRESS: "発送先住所",
    E_MG3002_ID: "集荷ID",
    E_MG3002_TIME_REQUEST: "ご依頼日時",
    E_MG3002_EMAIL: "会員メールアドレス",
    E_MG3002_ZIPCODE: "郵便番号",
    E_MG3002_CITY: "都道府県",
    E_MG3002_TOWN: "市区町村・番地",
    E_MG3002_APARTMENT: "アパート マンション名・部屋番号",
    E_MG3002_PHONE_COLLECT: "集荷先電話番号",
    E_MG3002_CODE_COLLECT: "集荷伝票番号",
    E_MG3002_BTN_SAVE: "この依頼をキャンセルする",
    E_MG3001_DATE_REQUEST: "依頼日",
    E_MG3001_DATE_COLLECT: "集荷日",
    E_MG3001_DATE_UPDATE: "更新日",
    EMPTY: "データはありません。",
    E_MG3001_DROP: {
        unCollectNew: '未処理',
        unCollectProcessing: '処理中',
        collected: '集荷処理済み',
        cancel: 'キャンセル済み',
    },
    E_MG2001_01: "依頼を選択してください。",
    E_MG99008_01: "「未処理」ステータス以外である依頼があります。再選択してください。",
    E_MG99008_02: "1回の最大出力件数は10000件です。ダイアログが閉じた後に、上限を超えたアイテムをもう一度出力してください",
    E_MG99017_1: "「内容確定」取り出し依頼がありません。出力が実行できないため再度確認してください。",
    E_MG4001_DROP: {
        boxNew: '未処理',
        boxProcess: '処理中',
        returnWait: '返却処理中',
        boxReturn: '返却済み',
        boxProcessing: '入庫処理中',
        boxHanging: '保管完了'
    },
    E_MG4001_TITLE: "ボックス管理",
    E_MG4001_ID: "ボックスID",
    E_MG4001_NAME: "会員名称",
    E_MG4001_DATE_COLLECT: "入庫日",
    E_MG4002_DATE_COLLECT: "入庫日(商品情報)",
    E_MG4001_NUMBER_ITEM: "アイテム数",
    E_MG4001_COLLECT_TIME: "入庫日時",
    E_MG4001_BOX_REGISTER: "入庫登録",
    E_MG4001_BOX_REGISTER_BTN: "登録",
    E_MG4001_BOX_NUMBER: "集荷数",
    E_MG4001_BOX_ID: "ボックスID/ボックスサイズ",
    E_MG4002_BOX_SIZE: "ボックスサイズ",
    E_MG4001_BOX_NAME: "ボックス名称",
    E_MG4001_BOX_CANCEL: "キャンセル",
    E_MG99015_02: "集荷依頼のボックス数が100箱を超えたら入力できないようになります。",
    E_MG99015_01: "依頼が存在しません、または「集荷済み」ステータス以外である。",
    S_MG10004_02: "登録しました。",
    S_MG10004_ADD_BOX: "ボックス追加",
    E_MG99015_BOX: {
        9101: 'ボックスが入庫されました。',
        9100: 'ボックスが入庫されました。',
        3105: '未完了ボックスお取り寄せ依頼のボックスです。',
        9102: 'システムに登録されていないボックスIDです。',
        9000: 'システムに登録されていないボックスIDです。',
    },

    E_MG99009_01: "依頼のステータスが変更されています。削除できません。",
    S_MG990022_01: "削除しました。",
    E_MG99022_DELETE_ITEM: {
        9200: {
            id: "S_MG990022_02",
            msg : "返却処理中のため削除できません。"
        },
        9201: {
            id: "S_MG990022_03 ",
            msg : "返却ずみのため削除できません。"
        },
        9202: {
            id: "S_MG990022_04",
            msg : "削除済みです。"
        },
        9203: {
            id: "S_MG990022_05",
            msg : "入庫済みのアイテムですから削除できません。"
        },
        9205: {
            id: "E_MG5002-07",
            msg : "サービス利用中のアイテムです。削除できません。"
        },
        9207: {
            id: "E_MG5002-07",
            msg : "サービス利用中のアイテムです。削除できません。"
        },
    },
    E_MG5002_UPDATE_ITEM: {
        9200: {
            id: "E_MG5002_02",
            msg : "返却処理中のため更新できません。"
        },
        9201: {
            id: "E_MG5002_03",
            msg : "返却済みのため更新できません。"
        },
        9202: {
            id: "E_MG5002_04",
            msg : "削除されているため更新できません。"
        },
        9205: {
            id: "E_MG5002-06",
            msg : "サービス利用中のアイテムです。編集できません。"
        },
        9206: {
            id: "E_MG5002-06",
            msg : "サービス利用中のアイテムです。編集できません。"
        }
    },
    E_MG5002_01: "ファイル形式が正しくありません。再選択してください。",
    E_MG5002_05: "ファイルサイズが上限を超えています。再選択してください。",
    E_MG4002_TITLE: "ボックス詳細",
    E_MG4002_NAME_USER: "会員名称",
    E_MG4002_INSURANCE: "安心補償",
    E_MG4002_MEMBER: "加入状況",
    E_MG4002_IS_MEMBER: "加入済み",
    E_MG4002_NOT_MEMBER: "未加入",
    E_MG4002_APPLY_DATE: "申し込み日時",
    E_MG4002_DETAIL: "商品情報",
    E_MG4002_REGISTER_ITEM: "アイテム登録",
    E_MG4002_LIST_BOX: "同じ集荷で入庫したボックス",
    E_MG4002_PRE_BOX: "前のボックスへ",
    E_MG4002_NEXT_BOX: "次のボックスへ",
    E_MG4002_REGISTER_BOX: "クローゼットへ登録",
    E_MG4002_ITEM_ID: "アイテムID",
    E_MG4002_ITEM_CATEGORY: "カテゴリー",
    E_MG4002_ITEM_IMAGE: "商品画像",
    E_MG4002_01: "クローゼットへの登録ボックスを選択してください。",
    S_MG4002_02: "「クローゼットへ登録」に成功しました。",
    E_MG4002_03: "チェック付けの「未処理」ボックスまたはチェック付けの「処理済み」ボックスのみをクローゼットへ登録します。再度確認してください。",
    E_MG4002_02: "チェック付けボックスの「未処理」アイテムのみをクローゼットへ登録します。再度確認してください。",
    E_MG4001_LIST_BOX_EXTEND: "追加ボックス一覧",
    E_MG4001_CHECK_BOX_REGISTER: "確認の上、作成します。",
    E_MG99027_01: "システムに存在していないボックスIDです。再度確認してください。",
    E_MG99027_02: "選択のボックスは返却処理中、または返却済み、保管完了済みです。新規ボックスが作成できません。",
    E_MG99016_DATE: "入庫日",
    E_MG99016_SELECT_IMG: "アイテム画像",
    E_MG99016_SELECT_IMG_BTN: "ファイルから読み込む",
    E_MG99016_SELECT_DROP_CATEGORY: "カテゴリー",
    E_MG99016_SAVE_ITEM: "登録完了",
    E_MG99016_SAVE_ITEM_NEXT: "次のアイテムを登録",
    E_MG99016_01: "ボックスのアイテム数が30件を超えました。",
    E_MG99016_02: "ボックスのステータスが変わりました。確認してください。",
    E_MG99027_DES: "追加ボックスは[〇]です。",
    E_MG4001_INPUT_CSV: "お荷物情報のCSV出力",
    E_MG4001_INPUT_CSV_ERR: "ボックスを選択してください。",
    E_MG99015_NAME: "会員名称",
    E_MG99029_TITLE: "追加ボックス一覧",
    E_MG99029_ID: "現行ボックスID",
    E_MG99029_ID_EXTEND: "追加ボックスID",
    E_MG99029_ID_DATE: "追加日時",
    E_MG11001_TITLE: "お知らせ管理",
    E_MG11001_ID: "お知らせID",
    E_MG11001_DATE: "配信開始日",
    E_MG11001_POST: "新規投稿",
    E_MG11001_TITLE_NOTI: "お知らせタイトル",
    E_MG11001_DATE_SEND: "配信開始日時",
    E_MG11001_DATE_END: "配信終了日時",
    E_MG8001_01: "前月の「未処理」取り出し依頼が存在しています。再度確認してから、内容を確定してください。",
    S_MG8001_01: "請求のステータス更新に成功しました。",
    S_MG8001_02: "請求作成処理の開始に成功しました。システムがユーザーの請求を作成しています。",
    E_MG99024_PAY_INVOICE: {
        10001: {
            id: "E_MG99024_01",
            msg:"未確定の請求がある場合一括請求が依頼できません。内容を再確認したら実行してください。",
        },
        10200: {
            id: "S_MG99024_02",
            msg: "利用月に請求がありません。再度確認してください。",
        },
    },
    S_MG99024_01 : "請求配信に成功しました。2時間後以降に請求結果を確認してください。",
    E_MG99016_NOTE: "アイテム画像を枠内にドラッグアンドドロップしてください。",
    E_MG99028_ID: "ID",
    E_MG99028_NAME_ADDRESS: "住所名称",
    E_MG99028_NAME: "氏名",
    E_MG99028_ZIPCODE: "郵便番号",
    E_MG99028_ADDRESS: "住所",
    E_MG99028_TOWN: "市区町村番地",
    E_MG99028_PHONE: "電話番号",
    E_MG99028_CLOSE: "戻る",
    E_MG7002_ADDRESS_OTHER: "登録住所",
    E_MG7002_ADDRESS_NAME: "住所の名称",
    E_MG11001_STATUS: {
        NEW: '未配信',
        SENT: '配信済み',
        END: '配信終了'
    },
    E_MG99020_UPDATE: {
        3303: {
            id: "3303",
            msg: "3303"
        },
        2800: {
            id: "E_MG99020_01",
            msg: "配信を終了しましたから修正できません。"
        },
        2801: {
            id: "E_MG99020_02",
            msg: "配信開始日時が現在日時より未来です。再度確認してください。"
        },
        2802: {
            id: "E_MG99020_03",
            msg: "配信開始日時が現在日時より未来にする必要があります。再度確認してください。"
        }
    },
    E_MG99020_CREATE: {
        2801: {
            id: "E_MG99020_02",
            msg: "配信開始日時が現在日時より未来です。再度確認してください。"
        },
        2802: {
            id: "E_MG99020_04",
            msg: "配信終了日時は配信開始日時より未来にする必要があります。再度確認してください。"
        }
    },

    S_MG99025_01: "請求情報の確定に成功しました。",
    E_MG99025_01: "請求のステータスが変わりました。請求確定ができません。",
    S_MG99026_01: "請求成功を確定しました。",
    E_MG99021_01: "お知らせのステータスが変わりましたので、再度確認してください。",
    E_MG99015_06: "ボックス個数が20より入力できないため再度入力してください。",
    E_MG8001_02: "読み込みに失敗しました。しばらく時間をおいて再度実行してください。",
    E_C_002:"正しく入力されていない項目がございます。もう一度ご入力お願いいたします。",
    E_MG99032_1: "ハンガー保管依頼のステータスが変わりましたのでハンガー保管依頼が登録できません。",
    E_MG99032_2: "ハンガー保管登録アイテムは取り出し依頼の対象です。ハンガー保管が登録できません。",
    E_MG99030_01_200: "200より大きい値は入力できないため再度入力してください。",
    E_MG99030_01: "カテゴリー名が存在しています。",
    E_MG99030_02: "アイテム包装用のボックス名が存在しています。",
    E_MG99030_03: "保管ボックス名が存在しています。",
    E_MG99041_1: 'ハンガー保管依頼のステータスが変わりました。ハンガー保管依頼がキャンセルできません。',
    E_MG99036_1: "シューズリペア依頼のステータスが変わりましたから、シューズリペア依頼が登録できません。",
    E_MG99037_1: "シューズリペア依頼のステータスが変わりましたから、シューズリペア依頼がキャンセルできません。",
    E_MG99038_1: "このシューズリペア依頼のステータスが変わりましたから再度確認してください。",
    E_MG99034_1: 'このクリーニング依頼のステータスが変わりましたのでクリーニング依頼がキャンセルできません。',
    E_MG99035_1: 'このクリーニング依頼のステータスが変わりましたので再度確認してください。',
    E_MG99033_1 : 'このクリーニング依頼のステータスが変わりましたのでクリーニング依頼が登録できません。',
    HANGER_WARNING: 'ハンガー保管しているアイテムがあります。取り出し忘れに注意してください。',
};

Object.freeze(MESSAGE);
