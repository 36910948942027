import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from '../Button/CustomButton';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: '#F2F2F2',
        borderRadius: '20px',
        width: 550,
    },
    rootDialogContentText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
        color: '#212121',
    },
    rootDialogActions: {
        justifyContent: 'center',
        gap: 50,
        paddingBottom: 30,
    },
    label: {
        fontWeight: 'bold',
        color: theme.palette.common.black,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialog(props) {
    const {
        isOpen,
        onSubmit,
        onClose,
        titleDialog,
        contentDialog,
        textSubmit,
        textCancel,
        twoButton,
    } = props;

    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id='alert-dialog-title'>{titleDialog}</DialogTitle>
            <DialogContent className={classes.contentText}>
                <DialogContentText
                    id='alert-dialog-description'
                    classes={{ root: classes.rootDialogContentText }}
                >
                    {contentDialog}
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.rootDialogActions }}>
                {twoButton && (
                    <CustomButton
                        handleClick={onClose}
                        color='default'
                        textButton={textCancel}
                        width='120'
                    />
                )}
                <CustomButton
                    handleClick={onSubmit}
                    color='primary'
                    textButton={textSubmit}
                    width='120'
                />
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    isOpen: false,
    onClose: () => {},
    onSubmit: () => {},
    titleDialog: undefined,
    contentDialog: undefined,
    textCancel: 'キャンセル',
    textSubmit: 'OK',
    twoButton: true,
};

AlertDialog.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    titleDialog: PropTypes.node,
    contentDialog: PropTypes.node,
    textCancel: PropTypes.string,
    textSubmit: PropTypes.string,
    twoButton: PropTypes.bool,
};
