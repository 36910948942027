import { call, takeLeading, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/Global';
import { waitAuthorizedCheck } from './Auth';

function* getListGroup() {
    try {
        const credential = yield call(waitAuthorizedCheck);
        const response = yield call(API.get, {
            endpoint: '/api/group/list',
            token: credential.data.access_token,
        });
        yield put(Actions.GetListGroupSuccess(response));
    } catch (error) {
        yield put(Actions.GetListGroupFailed(error));
    }
}

function* getListCategory() {
    try {
        const credential = yield call(waitAuthorizedCheck);
        const response = yield call(API.get, {
            endpoint: '/api/data/categories',
            token: credential.data.access_token,
        });
        yield put(Actions.GetListCategorySuccess(response));
    } catch (error) {
        yield put(Actions.GetListCategoryFailed(error));
    }
}

export default function* Global() {
    yield all([
        takeLeading('LOGIN_SUCCESS', getListGroup),
        takeLeading('LOGIN_SUCCESS', getListCategory),
        takeLeading('GET_LIST_CATEGORY_REQUEST', getListCategory),
    ]);
}
