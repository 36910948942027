import { createAction } from "redux-actions";

export const ListShoeRepairRequest            = createAction('LIST_SHOE_REPAIR_REQUEST');
export const ListShoeRepairSuccess            = createAction('LIST_SHOE_REPAIR_SUCCESS');
export const ListShoeRepairFailed             = createAction('LIST_SHOE_REPAIR_FAILED');
export const ListShoeRepairClear              = createAction('LIST_SHOE_REPAIR_CLEAR');

export const DetailShoeRepairRequest          = createAction('DETAIL_SHOE_REPAIR_REQUEST');
export const DetailShoeRepairSuccess          = createAction('DETAIL_SHOE_REPAIR_SUCCESS');
export const DetailShoeRepairFailed           = createAction('DETAIL_SHOE_REPAIR_FAILED');
export const DetailShoeRepairClear            = createAction('DETAIL_SHOE_REPAIR_CLEAR');

export const GetServiceOptionsRequest         = createAction('GET_SERVICE_OPTIONS_REQUEST');
export const GetServiceOptionsSuccess         = createAction('GET_SERVICE_OPTIONS_SUCCESS');
export const GetServiceOptionsFailed          = createAction('GET_SERVICE_OPTIONS_FAILED');
export const GetServiceOptionsClear           = createAction('GET_SERVICE_OPTIONS_CLEAR');

export const GetServicePricesRequest         = createAction('GET_SERVICE_PRICES_REQUEST');
export const GetServicePricesSuccess         = createAction('GET_SERVICE_PRICES_SUCCESS');
export const GetServicePricesFailed          = createAction('GET_SERVICE_PRICES_FAILED');
export const GetServicePricesClear           = createAction('GET_SERVICE_PRICES_CLEAR');

export const ProcessShoeRepairRequest         = createAction('PROCESS_SHOE_REPAIR_REQUEST');
export const ProcessShoeRepairSuccess         = createAction('PROCESS_SHOE_REPAIR_SUCCESS');
export const ProcessShoeRepairFailed          = createAction('PROCESS_SHOE_REPAIR_FAILED');
export const ProcessShoeRepairClear           = createAction('PROCESS_SHOE_REPAIR_CLEAR');

export const CompleteShoeRepairRequest        = createAction('COMPLETE_SHOE_REPAIR_REQUEST');
export const CompleteShoeRepairSuccess        = createAction('COMPLETE_SHOE_REPAIR_SUCCESS');
export const CompleteShoeRepairFailed         = createAction('COMPLETE_SHOE_REPAIR_FAILED');
export const CompleteShoeRepairClear          = createAction('COMPLETE_SHOE_REPAIR_CLEAR');

export const CancelShoeRepairRequest          = createAction('CANCEL_SHOE_REPAIR_REQUEST');
export const CancelShoeRepairSuccess          = createAction('CANCEL_SHOE_REPAIR_SUCCESS');
export const CancelShoeRepairFailed           = createAction('CANCEL_SHOE_REPAIR_FAILED');
export const CancelShoeRepairClear            = createAction('CANCEL_SHOE_REPAIR_CLEAR');
