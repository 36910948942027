import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/DataMaster';
import { waitAuthorizedCheck } from './Auth';

function* getCategoriesConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);
    const listServiceId = [7, 8, 9, 10];
    let paramsUrl = '';

    listServiceId.forEach((sId, index) => {
        if (index === listServiceId.length - 1) {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}`);
        } else {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}&`);
        }
    });

    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/data/categories?${paramsUrl}`,
            token: credential.data.access_token,
        });
        if (action.type === Actions.DataCategoriesRequest().type) {
            yield put(Actions.DataCategoriesSuccess(response));
        }
        if (action.type === Actions.DialogDataCategoriesRequest().type) {
            yield put(Actions.DialogDataCategoriesSuccess(response));
        }
    } catch (error) {
        if (action.type === Actions.DataCategoriesRequest().type) {
            yield put(Actions.DataCategoriesFailed(error));
        }
        if (action.type === Actions.DialogDataCategoriesRequest().type) {
            yield put(Actions.DialogDataCategoriesFailed(error));
        }
    }
}

function* updateCategoriesConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.postArray, {
            endpoint: '/api/admin/data/categories',
            body: action.payload,
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateCategoriesSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateCategoriesFailed(error));
    }
}

function* getNormalBoxConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);
    const listServiceId = [2, 3, 4, 5, 6];
    let paramsUrl = '';

    listServiceId.forEach((sId, index) => {
        if (index === listServiceId.length - 1) {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}`);
        } else {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}&`);
        }
    });
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/data/box/normal?${paramsUrl}`,
            token: credential.data.access_token,
        });
        if (action.type === Actions.DataBoxRequest().type) {
            yield put(Actions.DataBoxSuccess(response));
        }
        if (action.type === Actions.DialogDataBoxRequest().type) {
            yield put(Actions.DialogDataBoxSuccess(response));
        }
    } catch (error) {
        if (action.type === Actions.DataBoxRequest().type) {
            yield put(Actions.DataBoxFailed(error));
        }
        if (action.type === Actions.DialogDataBoxRequest().type) {
            yield put(Actions.DialogDataBoxFailed(error));
        }
    }
}

function* updateNormalBoxConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.postArray, {
            endpoint: '/api/admin/data/box/normal',
            body: action.payload,
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateBoxSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateBoxFailed(error));
    }
}

function* getReturnBoxConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);
    const listServiceId = [3];
    let paramsUrl = '';

    listServiceId.forEach((sId, index) => {
        if (index === listServiceId.length - 1) {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}`);
        } else {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}&`);
        }
    });
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/data/box/return?${paramsUrl}`,
            token: credential.data.access_token,
        });
        if (action.type === Actions.DataBoxReturnRequest().type) {
            yield put(Actions.DataBoxReturnSuccess(response));
        }
        if (action.type === Actions.DialogDataBoxReturnRequest().type) {
            yield put(Actions.DialogDataBoxReturnSuccess(response));
        }
    } catch (error) {
        if (action.type === Actions.DataBoxReturnRequest().type) {
            yield put(Actions.DataBoxReturnFailed(error));
        }
        if (action.type === Actions.DialogDataBoxReturnRequest().type) {
            yield put(Actions.DialogDataBoxReturnFailed(error));
        }
    }
}

function* updateBoxReturnConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.postArray, {
            endpoint: '/api/admin/data/box/return',
            body: action.payload,
            token: credential.data.access_token,
        });
        yield put(Actions.UpdateBoxReturnSuccess(response));
    } catch (error) {
        yield put(Actions.UpdateBoxReturnFailed(error));
    }
}

function* getServicePriceConfiguration() {
    const credential = yield call(waitAuthorizedCheck);
    const listServiceId = [7, 8, 9, 10, 11];
    let paramsUrl = '';

    listServiceId.forEach((sId, index) => {
        if (index === listServiceId.length - 1) {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}`);
        } else {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}&`);
        }
    });
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/data/service/price?${paramsUrl}`,
            token: credential.data.access_token,
        });
        yield put(Actions.DataServicePriceSuccess(response));
    } catch (error) {
        yield put(Actions.DataServicePriceFailed(error));
    }
}

function* getDialogDataServicePriceConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);

    let paramsUrl = '';
    action.payload.serviceIds.forEach((sId, index) => {
        if (
            index === action.payload.serviceIds.length - 1 ||
            action.payload.serviceIds.length === 1
        ) {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}`);
        } else {
            paramsUrl = paramsUrl.concat(`serviceIds=${sId}&`);
        }
    });

    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/data/service/price?${paramsUrl}`,
            token: credential.data.access_token,
        });
        yield put(
            Actions.DialogDataServicePriceSuccess({
                [action.payload.service]: response,
            })
        );
    } catch (error) {
        yield put(Actions.DialogDataServicePriceFailed(error));
    }
}

function* updateDataServicePriceConfiguration(action) {
    const credential = yield call(waitAuthorizedCheck);

    const updateSuccessActions = {
        service_hanger: 'UpdateServiceHangerSuccess',
        service_cleaning: 'UpdateServiceCleaningSuccess',
        service_shoe_repair: 'UpdateServiceShoeRepairSuccess',
        service_insurance: 'UpdateServiceInsuranceSuccess',
    };

    try {
        const response = yield call(API.putArray, {
            endpoint: '/api/admin/data/service/price',
            body: action.payload.body,
            token: credential.data.access_token,
        });
        yield put(
            Actions[updateSuccessActions[action.payload.service]](response)
        );
    } catch (error) {
        yield put(Actions.UpdateServicePriceFailed(error));
    }
}

export default function* DataMaster() {
    yield all([
        // Data Master Categories
        takeLatest('DATA_CATEGORIES_REQUEST', getCategoriesConfiguration),
        takeLatest(
            'DIALOG_DATA_CATEGORIES_REQUEST',
            getCategoriesConfiguration
        ),
        takeLatest('UPDATE_CATEGORIES_REQUEST', updateCategoriesConfiguration),

        // Data Master Box
        takeLatest('DATA_BOX_REQUEST', getNormalBoxConfiguration),
        takeLatest('DIALOG_DATA_BOX_REQUEST', getNormalBoxConfiguration),
        takeLatest('UPDATE_BOX_REQUEST', updateNormalBoxConfiguration),

        // Data Master Box Return
        takeLatest('DATA_BOX_RETURN_REQUEST', getReturnBoxConfiguration),
        takeLatest('DIALOG_DATA_BOX_RETURN_REQUEST', getReturnBoxConfiguration),
        takeLatest('UPDATE_BOX_RETURN_REQUEST', updateBoxReturnConfiguration),

        // Service Price
        takeLatest('DATA_SERVICE_PRICE_REQUEST', getServicePriceConfiguration),
        takeLatest(
            'DIALOG_DATA_SERVICE_PRICE_REQUEST',
            getDialogDataServicePriceConfiguration
        ),
        takeLatest(
            'UPDATE_SERVICE_PRICE_REQUEST',
            updateDataServicePriceConfiguration
        ),
    ]);
}
