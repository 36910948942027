import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Alert';
import { MESSAGE } from '../constants/Message';

const SnackbarReducer = handleActions(
    {
        [Actions.OpenSnackbar]: (state, action) => ({
            ...state,
            isOpen: true,
            severity: action.payload.severity,
            message: action.payload.message,
        }),
        [Actions.CloseSnackbar]: (state) => ({
            ...state,
            isOpen: false,
            severity: '',
            message: '',
        }),
    },
    {
        isOpen: false,
        severity: 'info',
        message: '',
    }
);

const DialogNetworkErrorReducer = handleActions(
    {
        [Actions.OpenDialogNetworkError]: (state) => ({
            ...state,
            isOpen: true,
        }),
        [Actions.CloseDialogNetworkError]: (state) => ({
            ...state,
            isOpen: false,
        }),
    },
    {
        isOpen: false,
        content: MESSAGE.E_AP01_120_01,
    }
);

export default combineReducers({
    snackbar: SnackbarReducer,
    dialogNetworkError: DialogNetworkErrorReducer,
});
