import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// material-ui
import { ButtonBase, Box, useMediaQuery } from '@material-ui/core';
import LogoWeb1 from '../../assets/images/logo_web1.svg';

import { MENU_OPEN, SET_MENU } from '../../actions/customizationAction';
// ===========================|| MAIN LOGO ||=========================== //

const styleLogo = {
    display: 'block',
    flexGrow: 1,
    fontSize: '26px',
    alignSelf: 'center',
    cursor: 'pointer',
};

const LogoSection = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const itemHandler = (link, id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesMd) dispatch({ type: SET_MENU, opened: false });
        history.push(link);
    };

    return (
        <ButtonBase
            disableRipple
            onClick={() => itemHandler('/admin/order', 'order')}
        >
            <Box component='span' style={styleLogo}>
                <img
                    src={LogoWeb1}
                    alt='logo-dashboard'
                    style={{ width: 170 }}
                />
            </Box>
        </ButtonBase>
    );
};

export default LogoSection;
