import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 8,
        width: (props) => (props.width ? `${props.width}px` : '150px'),
    },
    label: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#ffffff',
    },

    default: {
        color: 'white',
        backgroundColor: '#8d8d8d',
        '&:hover,&:focus': {
            backgroundColor: '#8d8d8d',
        },
    },
    primary: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover,&:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    error: {
        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover,&:focus': {
            backgroundColor: theme.palette.error.main,
        },
    },
    success: {
        color: 'white',
        backgroundColor: theme.palette.success.dark,
        '&:hover,&:focus': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    disabled: {
        pointerEvents: 'none',
        color: 'white',
        backgroundColor: theme.palette.primary[200],
    },
    disabledError: {
        pointerEvents: 'none',
        color: 'white',
        backgroundColor: theme.palette.error.light,
    },
    disabledSuccess: {
        pointerEvents: 'none',
        color: 'white',
        backgroundColor: theme.palette.success[200],
    },
}));

const CustomButton = (props) => {
    const {
        handleClick,
        color,
        size,
        disabled,
        disabledError,
        disabledSuccess,
        textButton,
        fullWidth,
        className,
        icon,
        ...rest
    } = props;
    const classes = useStyles(props);

    const btnClasses = clsx({
        [classes.label]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.disabled]: disabled,
        [classes.disabledError]: disabledError,
        [classes.disabledSuccess]: disabledSuccess,
        [className]: className,
    });

    return (
        <Button
            variant='contained'
            fullWidth={fullWidth}
            size={size}
            onClick={handleClick}
            className={btnClasses}
            classes={{
                root: classes.root,
                label: classes.label,
            }}
            {...rest}
        >
            {icon && (
                <span style={{ marginRight: '15px', marginTop: '3px' }}>
                    {icon}
                </span>
            )}

            {textButton}
        </Button>
    );
};

CustomButton.defaultProps = {
    color: 'primary',
    handleClick: () => {},
    size: 'medium',
    textButton: 'button',
    fullWidth: false,
    disabled: false,
    disabledError: false,
    className: '',
    disabledSuccess: false,
    icon: '',
};

CustomButton.propTypes = {
    color: PropTypes.oneOf([
        'default',
        'primary',
        'secondary',
        'error',
        'info',
        'success',
        'warning',
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    handleClick: PropTypes.func,
    textButton: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledError: PropTypes.bool,
    disabledSuccess: PropTypes.bool,
    icon: PropTypes.string,
};

export default CustomButton;
