import { call, takeLatest, put, all } from 'redux-saga/effects';
import API from '../service/API';
import * as Actions from '../actions/BoxManage';
import { waitAuthorizedCheck } from './Auth';
import format from 'date-fns/format';
import { generatePresignedUrl } from './GeneratePresignedUrl';

function* getListBoxManage(action) {
    const credential = yield call(waitAuthorizedCheck);
    let payload;
    if (action.payload?.post) {
        payload = action.payload?.post;
        if (!action.payload?.post?.status) {
            payload['status'] = 'PROCESSING';
        }
    } else {
        payload = { status: 'PROCESSING' };
    }
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/box/search?size=30&page=${
                action.payload?.get?.page ?? 0
            }&sort=${action.payload?.get?.sort ?? 'receivedDate,desc'}`,
            body: { ...payload, service_id: 11 },
            token: credential.data.access_token,
        });
        yield put(Actions.ListBoxManageSuccess(response));
    } catch (error) {
        yield put(Actions.ListBoxManageFailed(error));
    }
}

function* exportCsv(action) {
    const credential = yield call(waitAuthorizedCheck);

    try {
        const response = yield call(API.postDownload, {
            endpoint: `/api/admin/box/export?check=${
                action.payload.check ?? true
            }`,
            token: credential.data.access_token,
            body: action.payload.boxIds ?? [],
            filename: `ボックス管理_${format(new Date(), 'yyyyMMdd')}.csv`,
        });
        yield put(Actions.ExportCsvBoxManageSuccess(response));
    } catch (error) {
        yield put(Actions.ExportCsvBoxManageFailed(error));
    }
}

function* getCollectId(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/order/collection/${action.payload}/completed`,
            token: credential.data.access_token,
        });
        yield put(Actions.GetIdCollectSuccess(response));
    } catch (error) {
        yield put(Actions.GetIdCollectFailed(error));
    }
}

function* getBoxId(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/box/${action.payload?.id}/new`,
            token: credential.data.access_token,
        });
        yield put(
            Actions.GetIdBoxSuccess({
                ...response,
                index: action.payload?.index,
            })
        );
    } catch (error) {
        yield put(
            Actions.GetIdBoxFailed({
                errors: {
                    ...error.errors,
                    index: action.payload?.index,
                },
            })
        );
    }
}

function* inputBox(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        yield call(API.post, {
            endpoint: `/api/admin/box/receive`,
            token: credential.data.access_token,
            body: { ...action.payload },
        });
        yield put(Actions.InputBoxSuccess(true));
    } catch (error) {
        yield put(Actions.InputBoxFailed(error));
    }
}

function* getDetailBox(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/box/${action.payload}?service_id=11`,
            token: credential.data.access_token,
        });
        yield put(Actions.DetailBoxManageSuccess(response));
    } catch (error) {
        yield put(Actions.DetailBoxManageFailed(error));
    }
}

function* registerCloset(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.postGeneratePresignedUrl, {
            endpoint: `/api/admin/box/closet`,
            token: credential.data.access_token,
            body: action.payload,
        });
        yield put(Actions.RegisterClosetSuccess(response));
    } catch (error) {
        yield put(Actions.RegisterClosetFailed(error));
    }
}

function* registerBox(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/box/extend/${action.payload}`,
            token: credential.data.access_token,
        });
        yield put(Actions.RegisterBoxSuccess(response));
    } catch (error) {
        yield put(Actions.RegisterBoxFailed(error));
    }
}

function* getBoxSize(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/admin/box/extend/${action.payload}/planbox`,
            token: credential.data.access_token,
        });
        yield put(Actions.GetBoxSizeSuccess(response));
    } catch (error) {
        yield put(Actions.GetBoxSizeFailed(error));
    }
}

function* registerItem(action) {
    const credential = yield call(waitAuthorizedCheck);
    let image_url = '';
    if (action.payload.file) {
        image_url = yield call(generatePresignedUrl, action.payload.file);
    }
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/box/${action.payload?.boxId}/item`,
            token: credential.data.access_token,
            body: {
                category_id: action.payload?.category_id,
                image_url,
            },
        });
        yield put(Actions.RegisterItemSuccess(response));
    } catch (error) {
        yield put(Actions.RegisterItemFailed(error));
    }
}

function* getBoxExtend(action) {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.post, {
            endpoint: `/api/admin/box/extend/search?page=${
                action.payload?.get?.page ?? 0
            }&sort=${action.payload?.get?.sort ?? 'createdDate,desc'}`,
            token: credential.data.access_token,
            body: { ...action.payload?.post },
        });
        yield put(Actions.GetBoxExtendSuccess(response));
    } catch (error) {
        yield put(Actions.GetBoxExtendFailed(error));
    }
}

function* getPlanBox() {
    const credential = yield call(waitAuthorizedCheck);
    try {
        const response = yield call(API.get, {
            endpoint: `/api/data/planbox/normal/list`,
            token: credential.data.access_token,
        });
        yield put(Actions.GetPlanBoxSuccess(response));
    } catch (error) {
        yield put(Actions.GetPlanBoxFailed(error));
    }
}

export default function* Collect() {
    yield all([
        takeLatest('LIST_BOX_MANAGE_REQUEST', getListBoxManage),
        takeLatest('EXPORT_CSV_BOX_MANAGE_REQUEST', exportCsv),
        takeLatest('GET_ID_COLLECT_REQUEST', getCollectId),
        takeLatest('GET_ID_BOX_REQUEST', getBoxId),
        takeLatest('INPUT_BOX_REQUEST', inputBox),
        takeLatest('DETAIL_BOX_MANAGE_REQUEST', getDetailBox),
        takeLatest('REGISTER_CLOSET_REQUEST', registerCloset),
        takeLatest('REGISTER_BOX_REQUEST', registerBox),
        takeLatest('REGISTER_ITEM_REQUEST', registerItem),
        takeLatest('GET_BOX_SIZE_REQUEST', getBoxSize),
        takeLatest('GET_BOX_EXTEND_REQUEST', getBoxExtend),
        takeLatest('GET_PLAN_BOX_REQUEST', getPlanBox),
    ]);
}
