import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Divider, List, Typography } from '@material-ui/core';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import _ from 'lodash';
// style constant
const useStyles = makeStyles((theme) => ({
    menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: '#212121',
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px',
    },
    subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: theme.darkTextSecondary,
        textTransform: 'capitalize',
    },
    menuDivider: {
        marginTop: '2px',
        marginBottom: '10px',
    },
}));

// ===========================|| SIDEBAR MENU LIST GROUP ||=========================== //

const NavGroup = ({ item }) => {
    const classes = useStyles();
    const isAdmin = useSelector((state) => state.auth.credential.isAdmin);
    const permissionUser = useSelector(
        (state) => state.auth.credential?.data?.permissions
    );

    // menu list collapse & items
    const items = item.children.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                if (isAdmin) {
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                } else if (
                    permissionUser &&
                    (permissionUser.includes(menu.permissions) ||
                        _.isUndefined(menu.permissions))
                ) {
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                }
                return null;
            case 'item':
                if (isAdmin)
                    return <NavItem key={menu.id} item={menu} level={1} />;
                else if (
                    permissionUser &&
                    permissionUser.includes(menu.permissions)
                )
                    return <NavItem key={menu.id} item={menu} level={1} />;
                else return null;
            default:
                return (
                    <Typography
                        key={menu.id}
                        variant='h6'
                        color='error'
                        align='center'
                    >
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List>{items}</List>

            {/* group divider */}
            <Divider className={classes.menuDivider} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object,
};

export default NavGroup;
