import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Invoice';

const listInvoiceReducer = handleActions(
    {
        [Actions.ListInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailInvoiceReducer = handleActions(
    {
        [Actions.DetailInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const invoicePeriodReducer = handleActions(
    {
        [Actions.InvoicePeriodRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.InvoicePeriodSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.InvoicePeriodFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.InvoicePeriodClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const createInvoiceReducer = handleActions(
    {
        [Actions.CreateInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CreateInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CreateInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.CreateInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const payInvoiceReducer = handleActions(
    {
        [Actions.PayInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.PayInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.PayInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.PayInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const confirmReducer = handleActions(
    {
        [Actions.ConfirmInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ConfirmInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.ConfirmInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ConfirmInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            confirmData: {},
            errors: {},
        }),
        [Actions.SaveConfirmInvoice]: (state, action) => ({
            ...state,
            confirmData: action.payload,
        }),
        [Actions.SaveConfirmInvoiceClear]: (state) => ({
            ...state,
            confirmData: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        confirmData: {},
        data: {},
        errors: {},
    }
);

const unConfirmedReducer = handleActions(
    {
        [Actions.UnconfirmedCountRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UnconfirmedCountSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UnconfirmedCountFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UnconfirmedCountClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const paidInvoiceReducer = handleActions(
    {
        [Actions.PaidInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.PaidInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.PaidInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.PaidInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const updateInvoiceReducer = handleActions(
    {
        [Actions.UpdateInvoiceRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateInvoiceSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateInvoiceFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateInvoiceClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listInvoiceReducer,
    detail: detailInvoiceReducer,
    period: invoicePeriodReducer,
    create: createInvoiceReducer,
    pay: payInvoiceReducer,
    confirm: confirmReducer,
    unconfirmed: unConfirmedReducer,
    paid: paidInvoiceReducer,
    update: updateInvoiceReducer,
});
