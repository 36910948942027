import { fork } from 'redux-saga/effects';
import Global from './Global';
import Auth from './Auth';
import Box from './Box';
import Account from './Account';
import Membership from './Membership';
import Collect from './Collect';
import Order from './Order';
import BoxManage from './BoxManage';
import Item from './Item';
import Request from './Request';
import Notification from './Notification';
import Invoice from './Invoice';
import DataMaster from './DataMaster';
import Hanger from './Hanger';
import ShoeRepair from './ShoeRepair';
import Cleaning from './Cleaning';

export default function* root() {
    yield fork(Global);
    yield fork(Auth);
    yield fork(Box);
    yield fork(Account);
    yield fork(Membership);
    yield fork(Collect);
    yield fork(Order);
    yield fork(Item);
    yield fork(BoxManage);
    yield fork(Request);
    yield fork(Notification);
    yield fork(Invoice);
    yield fork(DataMaster);
    yield fork(Hanger);
    yield fork(ShoeRepair);
    yield fork(Cleaning);
}
