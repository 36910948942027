import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CloseSnackbar } from '../../actions/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles(() => ({
    message: {
        fontWeight: 'bold',
    },
}));

const CustomizedSnackbars = (props) => {
    const { isOpen, message, severity, onClose } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        if (onClose) onClose();
        else dispatch(CloseSnackbar());
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            color='inherit'
        >
            <Alert onClose={onClose} severity={severity}>
                <Typography variant='h6' className={classes.message}>
                    {message}
                </Typography>
            </Alert>
        </Snackbar>
    );
};

CustomizedSnackbars.defaultProps = {
    isOpen: false,
    message: '',
    severity: 'info',
};

CustomizedSnackbars.propTypes = {
    isOpen: PropTypes.bool,
    message: PropTypes.string,
    severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    onClose: PropTypes.func.isRequired,
};

export default CustomizedSnackbars;
