import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as Actions from '../actions/Notification';

const listNotificationReducer = handleActions(
    {
        [Actions.ListNotificationRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.ListNotificationSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            data: {
                count: action.payload.total_elements,
                total_pages: action.payload.total_pages,
                page: action.payload.page,
                rowsPerPage: action.payload.size,
                content: action.payload.content,
            },
            errors: {},
        }),
        [Actions.ListNotificationFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.ListNotificationClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const detailNotificationReducer = handleActions(
    {
        [Actions.DetailNotificationRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.DetailNotificationSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.DetailNotificationFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.DetailNotificationClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const createNotificationReducer = handleActions(
    {
        [Actions.CreateNotificationRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.CreateNotificationSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            showMsg: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.CreateNotificationFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            showMsg: false,
            errors: action.payload.errors,
        }),
        [Actions.CreateNotificationClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
        [Actions.CreateNotificationMsgClear]: (state) => ({
            ...state,
            showMsg: false,
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        showMsg: false,
        data: {},
        errors: {},
    }
);

const updateNotificationReducer = handleActions(
    {
        [Actions.UpdateNotificationRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.UpdateNotificationSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.UpdateNotificationFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.UpdateNotificationClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

const endNotificationReducer = handleActions(
    {
        [Actions.EndNotificationRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [Actions.EndNotificationSuccess]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: true,
            data: action.payload,
            errors: {},
        }),
        [Actions.EndNotificationFailed]: (state, action) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            errors: action.payload.errors,
        }),
        [Actions.EndNotificationClear]: (state) => ({
            ...state,
            isFetching: false,
            isSuccess: false,
            data: {},
            errors: {},
        }),
    },
    {
        isFetching: false,
        isSuccess: false,
        data: {},
        errors: {},
    }
);

export default combineReducers({
    list: listNotificationReducer,
    detail: detailNotificationReducer,
    create: createNotificationReducer,
    update: updateNotificationReducer,
    end: endNotificationReducer,
});
