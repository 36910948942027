// ===========================|| Get Difference 2 Array ||=========================== //

export const differenceArray = (array1, array2, property1, property2) => {
    return array1.filter((object1) => {
        return !array2.some((object2) => {
            return (
                object1[property1] === object2[property1] &&
                object1[property2] === object2[property2]
            );
        });
    });
};
